<!--
    Created by 程雨喵'mac on 2024/7/15.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：订单详情-订单功率图
-->
<style lang="less">
.order-detail-info {
  padding: @containerGap;
  .order-detail-row {
    &+.order-detail-row {
      margin-top: 20px;
    }
    .row-box {
      margin-top: @containerGap;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid @lineColorNormal;
      border-right: 1px solid @lineColorNormal;
      border-radius: @borderRadiusMin;
      overflow: hidden;
      line-height: 18px;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: @lineColorNormal;
      }
      .row-col {
        display: flex;
        font-size: 11px;
        border-bottom: 1px solid @lineColorNormal;
        .row-col-label {
          .display-flex();
          justify-content: flex-end;
          margin-right: 10px;
          padding: 6px 10px 6px 0;
          .flex-grow(125px);
          color: @textColorLight;
          background-color: @backColorNormal;
          border-left: 1px solid @lineColorNormal;
          border-right: 1px solid @lineColorNormal;
        }
        .row-col-value {
          .display-flex();
          justify-content: flex-start;
          padding: 6px 0;
          .row-col-span {
            height: 18px;
            line-height: 16px;
            padding: 0 8px;
            border-radius: @borderRadiusMin;
            border: 1px solid @themeColor;
            color: @textColorPrimary;
            background-color: rgba(84,110,253,0.15);
            cursor: default;
            font-size: 11px;
          }
          &.is-blur {
            filter: blur(5px);
          }
          &.text-err {
            color: @errorColor;
            font-weight: 600;
          }
          &.text-strong {
            color: @adornColor;
            font-weight: 600;
          }
          &.text-minus {
            color: @finishColor;
            font-weight: 600;
          }
          .ivu-icon {
            margin-left: 2px;
            color: @textColorLight;
          }
          &.can-click {
            color: @textColorPrimary;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="order-detail-info">
    <div
      v-for="(item, i) in infoItems"
      v-if="item.isShow"
      :key="i"
      class="order-detail-row"
    >
      <div class="h4">
        {{ item.title }}
      </div>
      <yg-reactive-box
        class="row-box"
      >
        <yg-reactive-col
          v-for="(sub, s) in item.items"
          v-if="sub.isShow"
          :key="'s-' + s"
          :column-number="3"
          class="row-col"
        >
          <div class="row-col-label">
            {{ sub.label }}:
          </div>
          <div
            class="row-col-value"
            :class="`${sub.canClick && model[item.key][sub.key] ? 'can-click' : ''} ${sub.className || ''} ${(!sub.needShowPrice || showPrice) ? '' : 'is-blur'}`"
            @click.stop="_clickItem(sub.key)"
          >
            <span
              v-if="sub.showTag"
              class="row-col-span"
            >
              {{ model[item.key][sub.key] || '-' }}
            </span>
            <yg-attribute-span
              v-else
              :text="model[item.key][sub.key]"
              :can-hide-phone="!!sub.canHidePhone"
              :can-price="!!sub.canPrice"
              :can-copy="!!sub.canCopy"
              :can-tooltip="!!sub.canTooltip"
              :can-text-unit="sub.textUnit"
            />
            <Poptip
              v-if="sub.showUnitTip && model[item.key][sub.key]"
              :width="280"
              :word-wrap="true"
              trigger="hover"
              :transfer="true"
            >
              <div
                slot="content"
                v-html="popTipContent"
              />
              <Icon type="md-information-circle" />
            </Poptip>
          </div>
        </yg-reactive-col>
      </yg-reactive-box>
    </div>
    <!--弹窗：查看各类型订单-->
    <yg-order-list
      v-if="!!dialog.model"
      :parameter-type="dialog.parameterType"
      :type="dialog.type"
      :model="dialog.model"
      @on-close="dialog.model = null"
    />
    <v-rules
      v-if="dialog.rulesModel"
      :model="dialog.rulesModel"
      @on-close="dialog.rulesModel = null"
    />
  </div>
</template>

<script>
import vRules from './deviceRules.vue';
export default {
  components: { vRules },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showPrice: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      popTipContent: '<div style="font-size: 12px; line-height: 22px"><div style="font-size: 14px;font-weight: 600">单位说明</div><div style="border-bottom: 1px solid #ccc; margin: 5px 0"></div><p class="display-flex"><span style="flex: 1; text-align: left">支付渠道为微信支付时</span><span class="flex: 1; text-align: right; font-weight: 600;">单位为“￥/元”</span></p><p class="display-flex"><span style="flex: 1; text-align: left">支付渠道为充电币账户(或余额)时</span><span class="flex: 1; text-align: right; font-weight: 600;">单位为“币”</span></p><div style="border-bottom: 1px solid #ccc; margin: 5px 0"></div><p>*注：仅在订单详情中适用</p></div>',
      dialog: {
        type: -1,
        parameterType: -1,
        model: null,
        // 计费规则弹窗
        rulesModel: null
      }
    };
  },
  computed: {
    infoItems () {
      // 2:是微信，4：余额
      const isCash = this.model.payInfo.channelId === '2';
      // 退款信息是否展示
      const refundInfoIsShow = this.model.refundInfo.refundMoney > 0 || this.model.refundInfo.manualRefundMoney > 0;
      // 自动退款items是否展示
      const automaticRefundItemsIsShow = this.model.refundInfo.refundMoney > 0;
      // 人工退款items是否展示
      const manualRefundItemsIsShow = this.model.refundInfo.manualRefundMoney > 0;
      // 默认 isShow:true
      const defaultItemsIsShow = true;
      return [
        {
          title: '用户信息',
          key: 'userInfo',
          isShow: defaultItemsIsShow,
          items: [
            { label: '用户手机号', key: 'userTel', canHidePhone: true, isShow: defaultItemsIsShow },
            { label: '启动方式', key: 'startupType', isShow: defaultItemsIsShow },
            { label: '充电卡号', key: 'chargeNumber', canCopy: true, isShow: defaultItemsIsShow }
          ]
        },
        {
          title: '充电订单信息',
          key: 'orderInfo',
          isShow: defaultItemsIsShow,
          items: [
            { label: '充电订单编码', key: 'orderNo', canCopy: true, isShow: defaultItemsIsShow },
            { label: '充电状态', key: 'chargeStatusDesc', isShow: defaultItemsIsShow },
            { label: '结束原因', key: 'endReason', className: 'text-err', isShow: defaultItemsIsShow },
            { label: '端口', key: 'displayCode', textUnit: 'num', isShow: defaultItemsIsShow },
            { label: '最大功率', key: 'power', textUnit: 'w', isShow: defaultItemsIsShow },
            // { label: '结算功率', key: 'power', textUnit: 'w', isShow: defaultItemsIsShow },
            // { label: '计费类型', key: 'categoryDesc', canClick: true, isShow: defaultItemsIsShow },
            { label: '订单支付金额', key: 'payMoney', needShowPrice: true, canPrice: isCash, textUnit: isCash ? '' : 'b', showUnitTip: true, isShow: defaultItemsIsShow },
            { label: '订单实收金额', key: 'realMoney', needShowPrice: true, className: 'text-strong', canPrice: isCash, textUnit: isCash ? '' : 'b', showUnitTip: true, isShow: defaultItemsIsShow },
            { label: '可分账金额', key: 'canAllocateMoney', needShowPrice: true, canPrice: isCash, textUnit: isCash ? '' : 'b', showUnitTip: true, isShow: defaultItemsIsShow },
            { label: '订单消费金额', key: 'orderCostMoney', needShowPrice: true, className: 'text-strong', canPrice: isCash, textUnit: isCash ? '' : 'b', showUnitTip: true, isShow: defaultItemsIsShow },
            { label: '累计退款金额', key: 'totalRefundMoney', needShowPrice: true, className: 'text-strong', canPrice: isCash, textUnit: isCash ? '' : 'b', showUnitTip: true, isShow: defaultItemsIsShow },
            { label: '消费明细', key: 'h5ConsumptionDetail', canTooltip: true, isShow: defaultItemsIsShow }
          ]
        },
        {
          title: '结算信息',
          key: 'orderSettleInfo',
          isShow: defaultItemsIsShow,
          items: [
            { label: '计费类型', key: 'categoryDesc', canClick: true, isShow: defaultItemsIsShow },
            { label: '结算功率', key: 'power', textUnit: 'w', isShow: defaultItemsIsShow },
            { label: '结算总电量', key: 'damageAfterChargeCapacity', textUnit: 'du', isShow: defaultItemsIsShow },
            { label: '结算时长', key: 'settleTime', textUnit: 'min', isShow: defaultItemsIsShow }
          ]
        },
        {
          title: '电量信息',
          key: 'capacityInfo',
          isShow: defaultItemsIsShow,
          items: [
            { label: '计算总电量', key: 'chargeCapacity', textUnit: 'du', isShow: defaultItemsIsShow },
            { label: '设备总电量', key: 'road', textUnit: 'du', isShow: defaultItemsIsShow }
          ]
        },
        {
          title: '充电时间',
          key: 'orderTimeInfo',
          isShow: defaultItemsIsShow,
          items: [
            { label: '开始时间', key: 'startTime', isShow: defaultItemsIsShow },
            { label: '预计结束', key: 'expectedEndTime', isShow: defaultItemsIsShow },
            { label: '实际结束', key: 'realEndTime', isShow: defaultItemsIsShow },
            { label: '已充电时长', key: 'chargedTime', textUnit: 'min', isShow: defaultItemsIsShow },
            { label: '剩余充电时长', key: 'remainTime', textUnit: 'min', isShow: defaultItemsIsShow }
          ]
        },
        {
          title: '付款信息',
          key: 'payInfo',
          isShow: defaultItemsIsShow,
          items: [
            { label: '支付金额', key: 'amount', needShowPrice: true, className: 'text-strong', canPrice: isCash, textUnit: isCash ? '' : 'b', showUnitTip: true, isShow: defaultItemsIsShow },
            { label: '支付状态', key: 'payStatus', isShow: defaultItemsIsShow },
            { label: '支付订单编码', key: 'payTradeNo', canClick: true, isShow: defaultItemsIsShow },
            { label: '支付渠道', key: 'channelName', showTag: true, isShow: defaultItemsIsShow },
            { label: '支付时间', key: 'orderTime', isShow: defaultItemsIsShow },
            { label: '交易渠道订单', key: 'gwTradeNo', isShow: defaultItemsIsShow },
            { label: '渠道服务费', key: 'payServiceRate', needShowPrice: true, className: 'text-minus', canPrice: true, isShow: defaultItemsIsShow }
          ]
        },
        {
          title: '退款信息',
          key: 'refundInfo',
          isShow: refundInfoIsShow,
          items: [
            { label: '退款金额', key: 'refundMoney', needShowPrice: true, className: 'text-minus', canPrice: isCash, textUnit: isCash ? '' : 'b', showUnitTip: true, isShow: automaticRefundItemsIsShow },
            { label: '退款状态', key: 'payStatus', isShow: automaticRefundItemsIsShow },
            { label: '退款订单编码', key: 'outTradeNo', canClick: true, isShow: automaticRefundItemsIsShow },
            { label: '退款交易类型', key: 'refundType', showTag: true, isShow: automaticRefundItemsIsShow },
            { label: '退款渠道', key: 'channelName', isShow: automaticRefundItemsIsShow },
            { label: '退款时间', key: 'orderTime', isShow: automaticRefundItemsIsShow },
            // { label: '凭证号', key: 'gwTradeNo' },
            { label: '源支付订单退款后余额', needShowPrice: true, key: 'sourceResidueAmount', canPrice: isCash, textUnit: isCash ? '' : 'b', showUnitTip: true, isShow: automaticRefundItemsIsShow },
            { label: '退款业务类型', key: 'refundBizType', isShow: automaticRefundItemsIsShow },
            { label: '操作人信息', key: 'refundOperator', isShow: automaticRefundItemsIsShow },

            { label: '退款金额', key: 'manualRefundMoney', needShowPrice: true, className: 'text-minus', canPrice: isCash, textUnit: isCash ? '' : 'b', showUnitTip: true, isShow: manualRefundItemsIsShow },
            { label: '退款状态', key: 'manualPayStatus', isShow: manualRefundItemsIsShow },
            { label: '退款订单编码', key: 'manualOutTradeNo', canClick: true, isShow: manualRefundItemsIsShow },
            { label: '退款交易类型', key: 'manualRefundType', showTag: true, isShow: manualRefundItemsIsShow },
            { label: '退款渠道', key: 'manualChannelName', isShow: manualRefundItemsIsShow },
            { label: '退款时间', key: 'manualOrderTime', isShow: manualRefundItemsIsShow },
            { label: '源支付订单退款后余额', needShowPrice: true, key: 'manualSourceResidueAmount', canPrice: isCash, textUnit: isCash ? '' : 'b', showUnitTip: true, isShow: manualRefundItemsIsShow },
            { label: '退款业务类型', key: 'manualRefundBizType', isShow: manualRefundItemsIsShow },
            { label: '操作人信息', key: 'manualOperator', isShow: manualRefundItemsIsShow },
            { label: '退款备注', key: 'manualRemark', isShow: manualRefundItemsIsShow }
          ]
        },
        {
          title: '分账信息',
          key: 'allocateInfo',
          isShow: defaultItemsIsShow,
          items: [
            { label: '服务商分账金额', key: 'spAllocateMoney', needShowPrice: true, canPrice: true, isShow: defaultItemsIsShow },
            { label: '物业公司分账金额', key: 'pmAllocateMoney', needShowPrice: true, canPrice: true, isShow: defaultItemsIsShow },
            { label: '代理商分账金额', key: 'agentAllocateMoney', needShowPrice: true, canPrice: true, isShow: defaultItemsIsShow }
          ]
        },
        {
          title: '设备实时信息',
          key: 'deviceInfo',
          isShow: defaultItemsIsShow,
          items: [
            { label: '设备编码', key: 'deviceNumber', canClick: true, isShow: defaultItemsIsShow },
            { label: '设备名称', key: 'name', isShow: defaultItemsIsShow },
            { label: 'IMEI号', key: 'imei', isShow: defaultItemsIsShow },
            { label: '省市区', key: 'addressName', isShow: defaultItemsIsShow },
            { label: '小区名称', key: 'communityName', isShow: defaultItemsIsShow },
            { label: '点位名称', key: 'siteName', isShow: defaultItemsIsShow },
            { label: '设备型号名称', key: 'deviceModel', isShow: defaultItemsIsShow },
            { label: '设备商名称', key: 'deviceProviderName', isShow: defaultItemsIsShow },
            { label: '端口最大功率', key: 'portMaxPower', textUnit: 'w', isShow: defaultItemsIsShow },
            { label: '结算功率虚位(瓦)', key: 'h5FictitiousPower', textUnit: '', isShow: defaultItemsIsShow },
            { label: '6分钟内免费充电', key: 'h5FreeConfig', isShow: defaultItemsIsShow },
            { label: '刷卡扣充电币(数量)', key: 'cardReduceChargeCoinNum', isShow: defaultItemsIsShow },
            { label: '最大充电比例', key: 'h5MaxChargingRatio', textUnit: '', isShow: defaultItemsIsShow },
            { label: '线损比例', key: 'h5LineLossRatio', textUnit: '', isShow: defaultItemsIsShow },
            { label: '最低消费电量(度)', key: 'minCostCapacity', textUnit: 'du', isShow: defaultItemsIsShow },
            { label: '运营类型', key: 'operationType', showTag: true, isShow: defaultItemsIsShow },
            { label: '运维服务联系人', key: 'contactName', isShow: defaultItemsIsShow },
            { label: '运维服务手机号', key: 'contactMobile', canHidePhone: true, isShow: defaultItemsIsShow },
            { label: '所属办事处名称', key: 'officeName', isShow: defaultItemsIsShow },
            { label: '办事处总监姓名', key: 'directorName', isShow: defaultItemsIsShow },
            { label: '办事处总监手机', key: 'directorTel', canHidePhone: true, isShow: defaultItemsIsShow }
          ]
        }
      ];
    }
  },
  methods: {
    _clickItem (key) {
      if (key === 'payTradeNo') {
        // 支付订单号
        this.dialog.parameterType = 2;
        this.dialog.type = 3;
        this.dialog.model = {
          payTradeNo: this.model.payInfo[key]
        };
      } else if (key === 'outTradeNo' || key === 'manualOutTradeNo') {
        if (!this.model.refundInfo[key]) return;
        // 退款订单号
        this.dialog.parameterType = 2;
        this.dialog.type = 4;
        this.dialog.model = {
          refundOrderNo: this.model.refundInfo[key]
        };
      } else if (key === 'deviceNumber') {
        // 设备编码
        this.dialog.parameterType = 3;
        this.dialog.type = 5;
        this.dialog.model = {
          deviceNumber: this.model.deviceInfo[key]
        };
      } else if (key === 'categoryDesc') {
        if (this.model.deviceRuleInfos && this.model.deviceRuleInfos.configs.length) {
          this.dialog.rulesModel = this.model;
        } else {
          this.$Message.warning('暂无计费规则');
        }
      }
    }
  }
};
</script>
