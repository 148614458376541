/**
 * 全局配置文件
 * 外部使用：最好不要直接使用，已挂载到Vue，使用Vue.$config使用
 * @date:2022/09/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */
const isDev = process.env.VUE_APP_ENV === 'dev';
const isProd = process.env.VUE_APP_ENV === 'prod';
// const isIphone = navigator.userAgent.includes('iPhone');
// const isIpad = navigator.userAgent.includes('iPad');
// const isIOS = isIphone || isIpad;

const GLOBAL_CONFIG = {
  // isIOS: isIOS,
  /**
     * 替代 process.env.NODE_ENV === 'production'
     * 使用时直接 this.$config.iDev
     */
  isDev: isDev,
  isProd: isProd,
  env: process.env.VUE_APP_ENV,
  title: '智能充电管理平台',
  // token有效天数(后端是30天)
  tokenExpires: 1,
  baseUrl: process.env.VUE_APP_API_URL,
  // requestTimeout: 10000,
  requestTimeout: 10000,
  version: '1.13.0', // 经营报表
  pageSize: 20,
  moneyUnit: '￥',
  exportLimit: 100,
  mainKeyValue: [
    { label: '支付渠道', key: 'payTypeChannel' },
    { label: '设备类别', key: 'deviceType' },
    { label: '端口类别', key: 'chargingPileType' },
    { label: '对接厂商', key: 'factoryType' },
    { label: '设备功率', key: 'devicePowerType' },
    { label: '主体类型', key: 'principalType' },
    // { label: '外部渠道', key: 'outsideChannel' },
    // { label: '外部数据', key: 'outsideDataType' },
    { label: '外部订单数据', key: 'outsideOrderDataType' },
    { label: '外部设备数据', key: 'outsideDeviceDataType' },
    { label: '外部客户数据', key: 'outsideCustomerDataType' },
    { label: '订单数据来源', key: 'orderDataSourceType' },
    { label: '设备数据来源', key: 'deviceDataSourceType' },
    { label: '充电二维码域名', key: 'chargeQrcodeDomain' },
    { label: '前端二维码渠道枚举', key: 'frontQrcodeEnum' }
  ],
  // sim卡5-6位对应的运营商
  simCardEE: [
    {
      title: '移动',
      items: ['00', '02', '04', '07', '08', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29']
    },
    {
      title: '联通',
      items: ['01', '06', '09', '10', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39']
    },
    {
      title: '未知',
      items: ['05']
    },
    {
      title: '电信',
      items: ['03', '11', '12', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']
    }
  ],
  // sim卡省份
  simCardSS: {
    '01': '北京',
    '02': '天津',
    '03': '河北',
    '04': '山西',
    '05': '内蒙古',
    '06': '辽宁',
    '07': '吉林',
    '08': '黑龙江',
    '09': '上海',
    10: '江苏',
    11: '浙江',
    12: '安徽',
    13: '福建',
    14: '江西',
    15: '山东',
    16: '河南',
    17: '湖北',
    18: '湖南',
    19: '广东',
    20: '广西/海南',
    21: '海南/广西',
    22: '四川/重庆',
    23: '贵州',
    24: '云南/贵州',
    25: '西藏',
    26: '陕西',
    27: '甘肃',
    28: '青海',
    29: '宁夏',
    30: '新疆',
    31: '重庆',
    36: '集成电路创新中心',
    99: '通用'
  },
  // 20: 服务商 serviceProvider
  // 30: 设备商 deviceProvider
  // 40: 物业公司 propertyCompany
  systemUserType: {
    service: 20,
    device: 30,
    property: 40,
    admin: 99
  },
  // 订单列表抽屉嵌套极限
  orderListDrawerCountLimit: 5
};
export default GLOBAL_CONFIG;
