import { ChargingBusinessOrderListModel } from '@/pages/060__rechargeManager/chargingBusinessOrder/model/ChargingBusinessOrderDataModel';
const settleDesc = {
  1: { label: '结算功率:', key: 'settleInfo', unit: 'w' },
  2: { label: '结算电量:', key: 'settleInfo', unit: 'du' },
  3: { label: '结算时长:', key: 'settleInfo', unit: 'min' }
};
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('orderNo', '充电\n订单编码', 160).setValueButton([], (row, index) => {
          this.requestDetail(row.orderNo, 'detailModel');
        }),
        BaseTableColumn.initData('', '充电状态', 130).setKeyValueList([
          {
            label: '端口号:',
            labelWidth: 62,
            key: 'displayCode',
            attr: {
              canTextUnit: 'num',
              canClick: (row, index) => {
                return !!row.displayCode;
              }
            }
          },
          {
            label: '充电状态:',
            labelWidth: 62,
            key: 'chargeStatusDesc',
            attr: {
              className: (row, index) => {
                return 'order-li-status order-li-status-' + row.chargeStatus;
              }
            }
          }
        ], (row, index, key) => {
          this.dialog.chartsDataModel = row;
        }),
        BaseTableColumn.initData('', '计费类型', 140).setRender((h, { row, index }) => {
          const currentItem = settleDesc[row.category];
          return [
            h('yg-attribute-key-value', {
              props: {
                label: '计费类型:',
                labelWidth: 60,
                value: row.categoryDesc,
                canClick: !!row.categoryDesc
              },
              on: {
                'on-click': () => {
                  this.requestDetail(row.orderNo, 'deviceRulesModel');
                }
              }
            }),
            h('yg-attribute-key-value', {
              props: {
                label: currentItem.label,
                labelWidth: 60,
                key: currentItem.key,
                value: row[currentItem.key],
                canTextUnit: row[currentItem.key] || row[currentItem.key] === 0 ? currentItem.unit : ''
              }
            })
          ];
        }),
        BaseTableColumn.initData('endReasonDesc', '结束原因').setSpan({}),
        BaseTableColumn.initData('', '用户').setWidth(150).setSpan({}).setKeyValueList([
          { label: '启动方式:', labelWidth: 62, key: 'chargeTypeDesc', attr: {} },
          {
            label: '用户手机:',
            labelWidth: 62,
            key: 'userTel',
            attr: {
              canClick: (row, index) => {
                return row.sourceType === 0;
              },
              canHidePhone: (row, index) => {
                return row.sourceType === 0;
              },
              canTooltip: (row, index) => {
                return row.sourceType === 1;
              },
              tooltipText: '此数据为小橙数据来源支付用户编号'
            }
          },
          { label: '卡号:', labelWidth: 62, key: 'chargeNumber', attr: { canCopy: true } }
        ], (row, index, key) => {
          this.dialog.parameterType = 1;
          this.dialog.orderListType = 1;
          this.dialog.orderListModel = row;
        }),
        BaseTableColumn.initData('', '订单金额').setWidth(110).setSpan({}).setKeyValueList([
          { label: '支付:', labelWidth: 40, key: 'payMoney', attr: { canPrice: true } },
          { label: '消费:', labelWidth: 40, key: 'consumeMoney', attr: { canPrice: true } },
          { label: '退款:', labelWidth: 40, key: 'h5RefundTotal', attr: { canPrice: true, canClick: true } }
        ], (row, index, key) => {
          if (key === 'h5RefundTotal') {
            if (!row.orderNo || row.h5RefundTotal <= 0) return;
            this.dialog.parameterType = 2;
            this.dialog.orderListType = 4;
            this.dialog.orderListModel = row;
          }
        }),
        BaseTableColumn.initData('', '充电时长').setWidth(120).setSpan({}).setKeyValueList([
          { label: '预计:', labelWidth: 40, key: 'expectedChargeDuration', attr: { canTooltip: true, canTextUnit: 'min' } },
          { label: '实际:', labelWidth: 40, key: 'realChargeDuration', attr: { canTooltip: true, canTextUnit: 'min' } }
        ]),
        BaseTableColumn.initData('', '充电时间').setSpan({}).setWidth(190).setKeyValueList([
          { label: '开始时间:', labelWidth: 52, key: 'startTimeStr', attr: { canTooltip: false } },
          { label: '预计结束:', labelWidth: 52, key: 'expectedEndTimeStr', attr: { canTooltip: false } },
          { label: '实际结束:', labelWidth: 52, key: 'realEndTimeStr', attr: { canTooltip: false } }
          // { label: '取消时间:', labelWidth: 80, key: 'cancelTimeStr', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('payTradeNo', '支付\n订单编码', 160).setValueButton([], (row, index) => {
          this.dialog.parameterType = 2;
          this.dialog.orderListType = 3;
          row.payTradeNo = row.payTradeNo + '';
          this.dialog.orderListModel = row;
        }),
        BaseTableColumn.initData('setting', '操作').setWidth(140).setAlign('center').setRender((h, { row, index }) => {
          const buttonOptions = [{ title: '终止充电', key: 'endOrder', permission: 'system:biz:endOrder' }];
          // 人工退款是否可退（0：否，1可人工退）
          if (row.canManualRefund === 1) {
            buttonOptions.push({ title: '人工退款', key: 'refund', permission: 'system:admin:biz:manualRefund' });
          }
          return h('yg-table-buttons', {
            props: {
              items: buttonOptions
            },
            on: {
              'on-click': ({ key }) => {
                switch (key) {
                case 'endOrder':
                  if (row.chargeStatus === 0 || row.chargeStatus === 1) {
                    this.$Modal.confirm({
                      title: '操作提示',
                      content: '<p style="color:red">是否确认提交终止充电？</p><p>确认后，将立即结束充电、释放端口，并按实际充电情况进行原路退款</p>',
                      onOk: () => {
                        this.$ygLoading.show();
                        setTimeout(() => {
                          this.requestEndOrder(row);
                        }, 500);
                      }
                    });
                  } else {
                    this.requestEndOrder(row);
                  }
                  break;
                case 'refund':
                  this.dialog.refundModel = ChargingBusinessOrderListModel.initModelWithData(row);
                  break;
                }
              }
            }
          });
        })
      ]
    };
  }
};
