<!--
    Created by 程雨喵'mac on 2024/1/23.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：计费规则(充电桩管理+运营管理中使用)
-->
<style lang="less">
.charging-pie-setting-money-rules {
  .top {
    padding-bottom: @containerGap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .__g-button {
      height: auto;
    }
  }
  .category-b {
    padding-bottom: @containerGap;
  }
  .bottom-tip {
    .display-flex();
    font-size: 12px;
    color: @textColorLight;
    cursor: help;
    .ivu-icon {
      margin-right: 5px;
    }
  }
}
</style>

<template>
  <div class="charging-pie-setting-money-rules">
    <div class="top">
      <p class="h4">
        设备计费设置
      </p>
      <div>
        <yg-button
          type="text"
          @click="_clickChangeEdit"
        >
          {{ isEdit ? '退出编辑' : '编辑规则' }}
        </yg-button>
      </div>
    </div>
    <div class="category-b">
      <span>计费类型：</span>
      <RadioGroup
        v-model="currentCategory"
        @on-change="onChangeRadio"
      >
        <Radio
          v-for="(item, i) in categoryOption"
          :key="i"
          :label="item.value"
          :disabled="!isEdit"
        >
          {{ item.label }}
        </Radio>
      </RadioGroup>
    </div>
    <!--按功率计费-->
    <v-use-power
      v-if="currentCategory === 1"
      ref="usePowerRef"
      :is-edit="isEdit"
      :data-list="dataList"
    />
    <v-use-electric
      v-else-if="currentCategory === 2 && dataList.length"
      ref="useElectricRef"
      :is-edit="isEdit"
      :data-list="dataList"
      :model="priceModel"
    />
    <v-use-time
      v-else-if="currentCategory === 3 && dataList.length"
      ref="useTimeRef"
      :is-edit="isEdit"
      :data-list="dataList"
    />
    <div
      v-if="isEdit"
      class="padding-primary-top display-flex display-flex-h-between"
    >
      <div
        v-if="currentCategory === 1"
        class="bottom-tip"
        @click.stop="_clickShowTip"
      >
        <Icon type="md-help-circle" />
        <span>常见瓦数的每小时充电消耗度数</span>
      </div>
      <div v-else />
      <yg-button
        type="primary"
        @click="_clickSubmit"
      >
        提交数据
      </yg-button>
    </div>
  </div>
</template>

<script>
import { DevicePriceModel, DevicePriceDataModel } from '../../model/DevicePriceModel';
import DeviceApiSet from '@/request/api/DeviceApiSet';
import vUsePower from './priceSetting/usePower.vue';
import vUseElectric from './priceSetting/useElectric.vue';
import vUseTime from './priceSetting/useTime.vue';
export default {
  components: { vUsePower, vUseElectric, vUseTime },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      categoryOption: [
        { value: 1, label: '按功率计费' },
        { value: 2, label: '按电量计费' },
        { value: 3, label: '按时间计费' }
      ],
      currentCategory: 1,
      // 记录编辑之前的category，如果没有提交并退出编辑时需要复位
      lastCategory: -1,
      isEdit: false,
      priceModel: null,
      dataList: [],
      orgDataList: []
    };
  },
  created () {
    this.currentCategory = this.model.chargeCategory;
    this.requestData();
  },
  methods: {
    _clickShowTip () {
      const p1 = '<p>1、0-200W，每小时预计消耗0.2度电；</p>';
      const p2 = '<p>2、201W-300W，每小时预计消耗0.3度电；</p>';
      const p3 = '<p>3、301W-500W，每小时预计消耗0.5度电；</p>';
      const p4 = '<p>4、501W-700W，每小时预计消耗0.7度电；</p>';
      this.$Modal.info({
        title: '常见瓦数的每小时充电消耗度数',
        content: p1 + p2 + p3 + p4,
        okText: '我知道了'
      });
    },
    _clickChangeEdit () {
      if (this.isEdit) {
        // 关闭编辑-先查看是否有变化,没有变化就直接改状态，有变化就要提示
        const oJson = JSON.stringify(this.orgDataList);
        const dJson = JSON.stringify(this.dataList);
        if (oJson === dJson) {
          this.isEdit = false;
          if (this.lastCategory !== -1 && this.lastCategory !== this.currentCategory) {
            this.currentCategory = this.lastCategory;
            this.onChangeRadio();
          }
        } else {
          this.$Modal.confirm({
            title: '温馨提示',
            content: '数据暂未提交，是否确认退出？',
            onOk: () => {
              this.dataList = this.orgDataList;
              this.isEdit = false;
            }
          });
        }
      } else {
        // 开始编辑
        this.lastCategory = this.currentCategory;
        if (this.orgDataList.length === 0) {
          if (this.currentCategory === 1) {
            // 按功率
            this.$Message.success({
              content: '已帮您初始化2个档位，提交数据后生效',
              duration: 5
            });
            this.dataList = DevicePriceModel.createInitList();
          } else if (this.currentCategory === 2) {
            // 按电量
            this.$Message.success({
              content: '已帮您初始化1个时段，提交数据后生效',
              duration: 5
            });
            this.priceModel = DevicePriceDataModel.initModelWithData({});
            this.dataList = DevicePriceModel.createInitListByDian();
          }
        } else {
          const dataList = [];
          this.orgDataList.forEach(v => {
            dataList.push(DevicePriceModel.deepCopy(v));
          });
          this.dataList = dataList;
        }
        this.isEdit = true;
      }
    },

    _clickSubmit () {
      // 按功率计费
      if (this.currentCategory === 1) {
        if (this.$refs.usePowerRef.utilsPowerCheckEmpty() && this.$refs.usePowerRef.utilsPowerCheckValue() && this.$refs.usePowerRef.utilsTimeCheckEmpty()) {
          const configs = [];
          this.dataList.forEach((v, index) => {
            configs.push({
              level: v.level,
              category: v.category,
              roadRange: `${v.h5RangeMin}${DevicePriceModel.rangeSplitUnit}${index === (this.dataList.length - 1) ? '' : v.h5RangeMax}`,
              chargeDuration: v.chargeDuration,
              price: 100
            });
          });
          const params = {
            deviceId: this.model.id,
            configs
          };
          this.requestSubmit(params);
        }
      } else if (this.currentCategory === 2) {
        // 按电量
        if (this.$refs.useElectricRef.$checkSubmit().success) {
          const configs = [];
          this.dataList.forEach((v, index) => {
            configs.push({
              level: v.level,
              category: v.category,
              roadRange: `${v.h5RangeMin}${DevicePriceModel.rangeSplitUnit}${v.h5RangeMax}`,
              chargePrice: v.chargePrice,
              servicePrice: v.servicePrice
            });
          });
          const params = {
            deviceId: this.model.id,
            configs,
            timeServicePrice: this.priceModel.timeServicePrice,
            minServiceTime: this.priceModel.minServiceTime
          };
          this.requestSubmit(params);
        }
      } else if (this.currentCategory === 3) {
        // 按时长
        if (this.$refs.useTimeRef.$checkSubmit().success) {
          const configs = [];
          this.dataList.forEach((v, index) => {
            configs.push({
              level: v.level,
              category: v.category,
              roadRange: '0-0',
              price: 1,
              chargeDuration: v.chargeDuration
            });
          });
          const params = {
            deviceId: this.model.id,
            configs
          };
          this.requestSubmit(params);
        }
      }
    },
    onChangeRadio () {
      this.requestData();
    },
    requestData () {
      const api = DeviceApiSet.devicePrice;
      this.dataList = [];
      api.params = {
        category: this.currentCategory,
        deviceId: this.model.id
      };
      this.$http(api).then(res => {
        const resData = res.data;
        // resData.configs = [];
        // console.log(this.currentCategory);
        if (resData.configs.length) {
          const dataList = [];
          this.orgDataList = DevicePriceModel.initListWithDataList(resData.configs);
          this.priceModel = DevicePriceDataModel.initModelWithData(resData);
          this.orgDataList.forEach(v => {
            dataList.push(DevicePriceModel.deepCopy(v));
          });
          this.dataList = dataList;
        } else {
          if (this.isEdit) {
            if (this.currentCategory === 1) {
              // 按功率
              this.$Message.success({
                content: '已帮您初始化2个档位，提交数据后生效',
                duration: 5
              });
              this.dataList = DevicePriceModel.createInitList();
            } else if (this.currentCategory === 2) {
              // 按电量
              this.$Message.success({
                content: '已帮您初始化1个时段，提交数据后生效',
                duration: 5
              });
              this.priceModel = DevicePriceDataModel.initModelWithData(resData);
              this.dataList = DevicePriceModel.createInitListByDian();
            } else if (this.currentCategory === 3) {
              // 按时长
              this.$Message.success({
                content: '已帮您初始化，提交数据后生效',
                duration: 5
              });
              this.dataList = DevicePriceModel.createInitListByTime();
            }
          }
        }
      });
      // const resList = DevicePriceModel.createFakeDataList();
      // this.orgDataList = DevicePriceModel.initListWithDataList([]);
      // const dataList = [];
      // this.orgDataList.forEach(v => {
      //   dataList.push(DevicePriceModel.deepCopy(v));
      // });
      // this.dataList = dataList;
    },
    requestSubmit (params) {
      const api = DeviceApiSet.devicePriceSet;
      api.params = params;
      this.$http(api).then(res => {
        this.isEdit = false;
        this.lastCategory = -1;
        this.$Message.success('操作成功');
        this.requestData();
        this.$emit('on-refresh');
      });
    }
  }
};
</script>
