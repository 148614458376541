<!--
    Created by 程雨喵'mac on 2023/12/13.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    功能概述：右侧抽屉
-->
<style lang="less">
.top-bar-drawer {
  .top-bar-drawer-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    .scroll-box {
      padding-right: @containerGap;
      flex: 1;
      overflow: auto;
      .row-box {
        padding-bottom: @containerGap * 1.5;
        h4 {
          color: @textColorStrong;
          margin-bottom: @containerGap;
          padding-left: @containerGap;
          position: relative;
          line-height: 18px;
          font-size: 16px;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 1px;
            bottom: 1px;
            width: 4px;
            border-radius: 2px;
            background-color: @themeColor;
          }
        }
        .login-info {
          .login-info-row {
            display: flex;
            align-items: center;
            line-height: @formHeight;
            .label {
              margin-right: 10px;
              color: @textColorLight;
            }
            .value {}
          }
        }
        .qrcode {
          flex: 1;
          .display-flex();
          justify-content: center;
          align-items: center;
          flex-direction: column;
          &+.qrcode {
            margin-left: @containerGap;
          }
          img {
            width: 80%;
          }
          p {
            margin-top: 10px;
            width: 100%;
            text-align: center;
            font-size: 12px;
            color: @textColorLight;
          }
        }
        .time-top-box {
          padding: @containerGap * 0.8;
          font-size: 12px;
          background-color: @backColorNormal;
          border-radius: @borderRadiusMin;
          .time-current-version {
            display: inline-block;
            width: 45%;
            line-height: 18px;
            color: @textColorLight;
            .text-strong {
              color: @textColorNormal;
            }
            &+.time-current-version {
              width: 55%;
            }
          }
        }
        .time-line {
          //font-size: 12px;
          .time-header {
            font-weight: 600;
            margin-bottom: 8px;
            font-size: 12px;
            .v {
              margin-left: 2px;
              color: @textColorLight;
            }
          }
          .time-content-li {
            line-height: 18px;
            font-size: 12px;
            &+.time-content-li {
              margin-top: 5px;
            }
          }
        }
        .avatar-box {
          display: flex;
          flex-wrap: wrap;
          .avatar-item {
            margin-bottom: 5px;
            display: flex;
            .flex-grow(20%);
            cursor: pointer;
            &.is-current {
              img {
                border-color: @themeColor;
              }
            }
            img {
              border: 3px solid transparent;
              width: 60px;
              height: 60px;
              border-radius: 50%;
              box-sizing: border-box;
            }
          }
        }
      }
    }
    .bottom-box {
      .display-flex();
      height: 70px;
      .log-out {
        cursor: pointer;
        .display-flex();
        flex: 1;
        height: 40px;
        background-color: @buttonColorAdorn;
        border-radius: 20px;
        color: @textColorFlip;
        font-size: 15px;
      }
    }
  }
}
</style>

<template>
  <div
    v-transfer-dom
    :data-transfer="true"
    class="top-bar-drawer"
  >
    <yg-drawer
      v-if="visible"
      :show-bottom="false"
      :width="400"
      @on-close="_close"
    >
      <span slot="title"> {{ title }}</span>
      <div
        slot="content"
        class="top-bar-drawer-inner"
      >
        <div class="scroll-box">
          <!--修改密码-->
          <div class="row-box">
            <h4>账号信息</h4>
            <div class="login-info">
              <div class="login-info-row">
                <div class="label">
                  登录账号:
                </div>
                <div class="value">
                  {{ userInfo.username }}
                </div>
                <!--<yg-button-->
                <!--  type="text"-->
                <!--  style="margin-left: 3px"-->
                <!--  @click="_clickPassword"-->
                <!--&gt;-->
                <!--  快速切换-->
                <!--</yg-button>-->
                <!--<Icon type="md-arrow-dropdown" />-->
              </div>
              <div class="login-info-row">
                <div class="label">
                  账号名称:
                </div>
                <div class="value">
                  {{ userInfo.realName }}
                </div>
              </div>
              <div class="login-info-row">
                <div class="label">
                  登录密码:
                </div>
                <div class="value">
                  <yg-button
                    type="text"
                    @click="_clickPassword"
                  >
                    修改密码
                  </yg-button>
                </div>
              </div>
            </div>
          </div>
          <!--头像-->
          <div class="row-box">
            <h4>头像设置</h4>
            <div class="avatar-box">
              <div
                v-for="(item, i) in 10"
                :key="i"
                class="avatar-item"
                :class="currentAvatar === (i + '') ? 'is-current' : ''"
                @click.stop="_clickAvatar(i)"
              >
                <img
                  :src="`/avatar/user${i}.png`"
                >
              </div>
            </div>
          </div>
          <!--移动端二维码-->
          <div class="row-box">
            <h4>智能充电桩微信端</h4>
            <div class="display-flex">
              <div class="qrcode">
                <img :src="'/qrcode/merchant.jpg?t=' + picTime">
                <p>商户端小程序</p>
              </div>
              <div class="qrcode">
                <img :src="'/qrcode/customer.jpg?t=' + picTime">
                <p>消费端小程序</p>
              </div>
            </div>
          </div>
          <!--当前版本-->
          <div class="row-box">
            <h4>重大版本上线日志</h4>
            <div class="time-top-box">
              <div class="time-current-version">
                <span>当前版本:</span>
                <span class="text-strong">v{{ version }}</span>
              </div>
              <div
                class="time-current-version"
              >
                <span>已运行:</span>
                <span class="text-strong">{{ onLineTime }}</span>
              </div>
            </div>
            <div class="time-line">
              <Timeline style="margin-top: 15px">
                <TimelineItem
                  v-for="(item, i) in deployList"
                  :key="i"
                >
                  <p class="time-header">
                    <span class="t">{{ item.time }}</span>
                    <span class="v">({{ item.version }})</span>
                  </p>
                  <div>
                    <p
                      v-for="(chi, c) in item.content"
                      :key="'c-' + c"
                      class="time-content-li"
                    >
                      {{ chi }}
                    </p>
                  </div>
                </TimelineItem>
              </Timeline>
            </div>
          </div>
        </div>
        <!--退出登录-->
        <div class="bottom-box">
          <div
            class="log-out"
            @click.stop="_clickLogout"
          >
            退出登录
          </div>
        </div>
        <!--修改密码-->
        <v-password
          v-if="!!changePasswordUsername"
          :username="changePasswordUsername"
          @on-close="changePasswordUsername = null"
          @on-sure="onSurePassword"
        />
      </div>
    </yg-drawer>
  </div>
</template>

<script>
import GLOBAL_CONFIG from '@/app.config';
import APP_DEPLOY from '@/app.deploy';
import vPassword from './changePassword.vue';
import { isAlphanumeric } from '@/libs/regularUtils';
import CommonApiSet from '@/request/api/CommonApiSet';
import md5 from 'md5';
import BaseModel from '@/libs/base/dataModel/BaseModel';
function addZero (str) {
  const str1 = '0' + str + '';
  return str1.slice(str1.length - 2, str1.length);
}
export default {
  components: { vPassword },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      currentAvatar: '0',
      version: '',
      onLineTime: '',
      deployList: [],
      userInfo: {},
      changePasswordUsername: '',
      picTime: '',
      timer: null
    };
  },
  watch: {
    value () {
      this.visible = this.value;
      if (this.visible) {
        this.startTimer();
      } else {
        this.clearTimer();
      }
    }
  },
  created () {
    this.userInfo = this.$storage.user;
    this.currentAvatar = this.$storage.avatar;
    this.version = GLOBAL_CONFIG.version;
    this.deployList = APP_DEPLOY();
    this.picTime = (new Date()).getTime();
  },
  destroyed () {
    this.clearTimer();
  },
  methods: {
    clearTimer () {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    startTimer () {
      this.clearTimer();
      this.setOnLineTime();
      this.timer = setInterval(() => {
        this.setOnLineTime();
      }, 1000);
    },
    setOnLineTime () {
      const onLine = this.deployList.find(v => v.version === 'v1.0.0');
      const passTime = (new Date()).getTime() - (new Date(onLine.time)).getTime();
      const day = Math.floor(passTime / 86400000);
      const passTime1 = passTime % 86400000;
      const hour = Math.floor(passTime1 / 3600000);
      const passTime2 = passTime1 % 3600000;
      const minute = Math.floor(passTime2 / 60000);
      const passTime3 = passTime2 % 60000;
      const second = Math.floor(passTime3 / 1000);
      this.onLineTime = `${day}天${addZero(hour)}小时${addZero(minute)}分${addZero(second)}秒`;
    },
    _clickAvatar (i) {
      if (this.$storage.avatar !== (i + '')) {
        this.$Modal.confirm({
          title: '温馨提示',
          content: '是否确定修改头像？',
          onOk: () => {
            this.$storage.avatar = i + '';
            this.$router.go(0);
          }
        });
      }
    },
    _clickPassword () {
      // 修改密码
      this.changePasswordUsername = this.userInfo.username;
    },
    _close () {
      this.visible = false;
      this.$emit('input', false);
      this.$emit('on-change', false);
    },
    _clickLogout () {
      this.$Modal.confirm({
        title: '温馨提示',
        content: '是否确定退出登录？',
        onOk: () => {
          this.$router.replace('/login');
        }
      });
    },
    onSurePassword (e) {
      let res = { success: true };
      if (!isAlphanumeric(e.password)) {
        res = BaseModel.getValidRes(false, '6~8位密码，必须为数字字母组合');
      } else if (e.password !== e.h5PasswordAgain) {
        res = BaseModel.getValidRes(false, '两次输入的密码不同，请重新输入');
      } else if (e.password === e.oldPassword) {
        res = BaseModel.getValidRes(false, '新密码不能与原密码一致');
      }
      if (res.success) {
        this.requestPassword({
          username: e.username,
          password: md5(e.password),
          oldPassword: md5(e.oldPassword)
        });
      } else {
        this.$Message.warning(res.msg);
      }
    },
    requestPassword (params) {
      const api = CommonApiSet.passwordUpdate;
      api.params = params;
      this.$http(api).then(res => {
        this.$Modal.confirm({
          title: '密码修改成功',
          content: '请重新登录系统',
          cancelText: ' ',
          okText: '我知道了',
          onOk: () => {
            this.$router.replace('/login');
          },
          onCancel: () => {
            this.$router.replace('/login');
          }
        });
      });
    }
  }
};
</script>
