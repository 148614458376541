// 用时间(版本)的倒序写
export default function () {
  return [
    {
      time: '2024-12-11',
      version: 'v1.13.0',
      content: [
        '1.设备经营报表；',
        '2.小区经营报表；',
        '3.办事处经营报表；'
      ]
    },
    {
      time: '2024-11-27',
      version: 'v1.12.3',
      content: [
        '1.充电桩计费模板：增加详情及编辑功能；',
        '2.充电桩分账模板：增加详情及编辑功能；',
        '2.充电桩运营模板：增加详情及编辑功能；'
      ]
    },
    {
      time: '2024-11-25',
      version: 'v1.12.2',
      content: [
        '1.导入设备出厂优化；',
        '2.设备出厂列表新增在线状态；'
      ]
    },
    {
      time: '2024-11-22',
      version: 'v1.12.1',
      content: [
        '1.修复电量计费bug；'
      ]
    },
    {
      time: '2024-11-20',
      version: 'v1.12.0',
      content: [
        '1.电量收费下按时长计收服务费；',
        '2.图表及日志快速预览；'
      ]
    },
    {
      time: '2024-11-07',
      version: 'v1.11.0',
      content: [
        '1.按充电时长计费；',
        '2.运营模板下发；',
        '3.设备信息及点位变更；',
        '4.尚亿设备对接；'
      ]
    },
    {
      time: '2024-09-29',
      version: 'v1.10.0',
      content: [
        '1.按电量计费；',
        '2.电量计费模板下发；'
      ]
    },
    {
      time: '2024-09-10',
      version: 'v1.9.0',
      content: [
        '1.计费模板下发；',
        '2.分账模板下发；'
      ]
    },
    {
      time: '2024-08-27',
      version: 'v1.8.0',
      content: [
        '1.扣减迁入金额功能；',
        '2.已结束订单原路退款；',
        '3.友电设备高级参数设置；'
      ]
    },
    {
      time: '2024-08-01',
      version: 'v1.7.0',
      content: [
        '1.修改全局数据来源；',
        '2.新增消费金额等；'
      ]
    },
    {
      time: '2024-07-19',
      version: 'v1.6.0',
      content: [
        '充电订单详情及其他优化'
      ]
    },
    {
      time: '2024-07-11',
      version: 'v1.5.0',
      content: [
        '1.新增统计报表；',
        '2.优化部分功能；'
      ]
    },
    {
      time: '2024-06-07',
      version: 'v1.4.0',
      content: [
        '余额账户及刷卡'
      ]
    },
    {
      time: '2024-05-10',
      version: 'v1.2.0',
      content: [
        '友电对接'
      ]
    },
    {
      time: '2024-04-30',
      version: 'v1.1.0',
      content: [
        '小橙对接'
      ]
    },
    {
      time: '2024-03-28',
      version: 'v1.0.0',
      content: [
        '第一个版本上线，此版本包含但不限于：',
        '1.登录页面；',
        '2.基础配置；',
        '3.点位管理；',
        '4.设备管理；'
      ]
    },
    {
      time: '2023-12-29',
      version: 'v0.0.0',
      content: [
        '呱呱坠地'
      ]
    }
  ];
}
