<!--
   界面布局-中间页

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
</style>

<template>
  <keep-alive>
    <router-view />
  </keep-alive>
</template>

<script>
export default {};
</script>
