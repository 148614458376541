/**
 * storage-前端存储
 * 外部使用：最好不要直接使用，已配置到YunGui全局属性中，使用时已 YunGui.$storage 使用
 *
 * @date:2021/11/08
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

import GLOBAL_CONFIG from '@/app.config.js';
import { saveStorage, loadStorage } from './utils.js';
import { encode, decode } from 'js-base64';
import {
  KEY_AVATAR,
  KEY_GLOBAL_SETTING,
  KEY_ANALYSIS,
  KEY_TOKEN,
  KEY_USER,
  KEY_MERCHANT,
  KEY_AUTO_CONFIG,
  KEY_LEFT_MENU,
  KEY_SAVE_PASSWORD,
  KEY_USERNAME_PASSWORD,
  KEY_THEME,
  KEY_YOU_DEVICE_PARAMS,
  KEY_ADMIN_LIST,
  KEY_DATA_ANALYSIS_COLUMN_KEY,
  GET_ALL_KEY
} from './keys.js';

class Storage {
  constructor () {
    // 默认是0
    this._avatar = '';
    // 1: light, 2: dark
    this._theme = '';
    /**
     * 全局设置 globalSetting
     * @page 列表分页： 默认10条
     * @money 金额展示：默认￥
     * @tableLine 数据列表行与行是否有分割线：默认有
     * @tableButton 数据列表按钮大于2个时，1：展示为「更多」，2：全部往下排；默认为 1
     * @tableExpand 数据列表是否默认展开，1：展开，2：不展开；默认为2
     * @escClose 按esc后关闭弹层。1：询问后关闭，2：直接关闭(默认为1)
     * @private
     */
    this._globalSetting = null;
    this._token = '';
    // 曾经登录过得账号(数据不要清空)
    this._adminLoginList = null;
    // 登录人信息
    this._user = '';
    // 登录人商户信息(如果是服务商-存的是服务商信息系，如果是设备商-存的是设备商信息，以此类推)
    this._merchantInfo = '';
    /**
     * 登录人拥有的所有菜单
     * {
     *   level1: [], // 所有一级菜单
     *   level2: [], // 所有二级菜单
     * }
     */
    this._leftMenus = null;
    this._lastPage = '';
    this._savePassword = false;
    this._usernamePassword = null;
    this._analysisData = null;
    this._autoConfig = null;
    // 友电设备高级参数
    this._youdianDeviceParams = null;
    /**
     * 数据统计里的列控制 KEY_DATA_ANALYSIS_COLUMN_KEY
     * {
     *   // tableKey 直接用页面的router的name就行
     *   tableKey: [// 需展示的key]
     * }
     */
    this._dataAnalysisColumn = null;
  }

  set dataAnalysisColumn ({ key, data }) {
    this.dataAnalysisColumn[key] = data;
    saveStorage(KEY_DATA_ANALYSIS_COLUMN_KEY, this._dataAnalysisColumn);
  }

  get dataAnalysisColumn () {
    if (!this._dataAnalysisColumn) {
      this._dataAnalysisColumn = loadStorage(KEY_DATA_ANALYSIS_COLUMN_KEY) || {};
    }
    return this._dataAnalysisColumn;
  }

  set youdianDeviceParams ({ key, data }) {
    this._youdianDeviceParams[key] = data;
    saveStorage(KEY_YOU_DEVICE_PARAMS, this._youdianDeviceParams, 30);
  }

  // key为设备id，value为对象
  get youdianDeviceParams () {
    if (!this._youdianDeviceParams) {
      this._youdianDeviceParams = loadStorage(KEY_YOU_DEVICE_PARAMS) || {};
    }
    return this._youdianDeviceParams;
  }

  set autoConfig (data) {
    this._autoConfig = data;
    if (data === null || data === '') {
      window.localStorage.removeItem(KEY_AUTO_CONFIG);
    } else {
      saveStorage(KEY_AUTO_CONFIG, data);
    }
  }

  get autoConfig () {
    if (!this._autoConfig) {
      this._autoConfig = loadStorage(KEY_AUTO_CONFIG) || { lastShowEscTip: 0 };
    }
    return this._autoConfig;
  }

  set analysisData (data) {
    this._analysisData = data;
    if (data === null || data === '') {
      window.localStorage.removeItem(KEY_ANALYSIS);
    } else {
      saveStorage(KEY_ANALYSIS, data);
    }
  }

  get analysisData () {
    if (!this._analysisData) {
      this._analysisData = loadStorage(KEY_ANALYSIS) || null;
    }
    return this._analysisData;
  }

  set theme (data) {
    if (this._theme !== data) {
      this._theme = data;
      if (data === null || data === '') {
        window.localStorage.removeItem(KEY_THEME);
      } else {
        saveStorage(KEY_THEME, data);
      }
    }
  }

  get theme () {
    if (!this._theme) {
      this._theme = loadStorage(KEY_THEME) || '1';
    }
    return this._theme;
  }

  set avatar (data) {
    if (this._avatar !== data) {
      this._avatar = data;
      if (data === null || data === '') {
        window.localStorage.removeItem(KEY_AVATAR);
      } else {
        saveStorage(KEY_AVATAR, data);
      }
    }
  }

  get avatar () {
    if (!this._avatar) {
      this._avatar = loadStorage(KEY_AVATAR) || '0';
    }
    return this._avatar;
  }

  set globalSetting (data) {
    if (this._globalSetting !== data) {
      this._globalSetting = data;
      if (data === null || data === '') {
        window.localStorage.removeItem(KEY_GLOBAL_SETTING);
      } else {
        saveStorage(KEY_GLOBAL_SETTING, data);
      }
    }
  }

  get globalSetting () {
    if (!this._globalSetting) {
      this._globalSetting = loadStorage(KEY_GLOBAL_SETTING) || { page: '20', money: '￥', tableLine: '1', tableButton: '1', tableExpand: '2', escClose: '1' };
    }
    return this._globalSetting;
  }

  set token (data) {
    if (this._token !== data) {
      this._token = data;
      if (data === null || data === '') {
        window.localStorage.removeItem(KEY_TOKEN);
      } else {
        saveStorage(KEY_TOKEN, data, GLOBAL_CONFIG.tokenExpires);
      }
    }
  }

  get token () {
    if (!this._token) {
      this._token = loadStorage(KEY_TOKEN);
    }
    return this._token;
  }

  set user (data) {
    if (this._user !== data) {
      this._user = data;
      if (data === null || data === '') {
        window.localStorage.removeItem(KEY_USER);
      } else {
        saveStorage(KEY_USER, data);
      }
    }
  }

  get user () {
    if (!this._user) {
      this._user = loadStorage(KEY_USER);
    }
    return this._user;
  }

  set adminLoginList ({ username, password }) {
    this.adminLoginList[username] = {
      username,
      password,
      lastLogin: (new Date()).getTime()
    };
    saveStorage(KEY_ADMIN_LIST, this._adminLoginList);
  }

  get adminLoginList () {
    if (!this._adminLoginList) {
      this._adminLoginList = loadStorage(KEY_ADMIN_LIST) || {};
    }
    return this._adminLoginList;
  }

  set merchantInfo (data) {
    if (this._merchantInfo !== data) {
      this._merchantInfo = data;
      if (data === null || data === '') {
        window.localStorage.removeItem(KEY_MERCHANT);
      } else {
        saveStorage(KEY_MERCHANT, data);
      }
    }
  }

  get merchantInfo () {
    if (!this._merchantInfo) {
      this._merchantInfo = loadStorage(KEY_MERCHANT);
    }
    return this._merchantInfo;
  }

  set leftMenus (data) {
    if (this._leftMenus !== data) {
      this._leftMenus = data;
      if (data === null || data === '') {
        window.localStorage.removeItem(KEY_LEFT_MENU);
      } else {
        saveStorage(KEY_LEFT_MENU, data);
      }
    }
  }

  get leftMenus () {
    if (!this._leftMenus) {
      this._leftMenus = loadStorage(KEY_LEFT_MENU) || {
        level1: [],
        level2: []
      };
    }
    return this._leftMenus;
  }

  set savePassword (data) {
    if (this._savePassword !== data) {
      this._savePassword = data;
      if (data === null || data === '') {
        window.localStorage.removeItem(KEY_SAVE_PASSWORD);
      } else {
        saveStorage(KEY_SAVE_PASSWORD, data);
      }
    }
  }

  get savePassword () {
    if (!this._savePassword) {
      this._savePassword = loadStorage(KEY_SAVE_PASSWORD) || false;
    }
    return this._savePassword;
  }

  set usernamePassword (data) {
    if (this._usernamePassword !== data) {
      if (data === null || data === '') {
        window.localStorage.removeItem(KEY_USERNAME_PASSWORD);
      } else {
        try {
          let newData = JSON.stringify(data);
          newData = encode(newData);
          this._usernamePassword = newData;
          saveStorage(KEY_USERNAME_PASSWORD, newData);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  get usernamePassword () {
    let data = loadStorage(KEY_USERNAME_PASSWORD);
    try {
      data = decode(data);
      data = JSON.parse(data);
      this._usernamePassword = data;
    } catch (e) {
      console.log(e);
    }
    return this._usernamePassword;
  }

  // 删除某个
  removeStorage (key) {
    switch (key) {
    case KEY_AVATAR:
      this.avatar = '';
      break;
    case KEY_GLOBAL_SETTING:
      this.globalSetting = null;
      break;
    case KEY_TOKEN:
      this.token = '';
      break;
    case KEY_USER:
      this.user = '';
      break;
    case KEY_SAVE_PASSWORD:
      this.savePassword = false;
      break;
    case KEY_USERNAME_PASSWORD:
      this.usernamePassword = null;
      break;
    }
    window.localStorage.removeItem(key);
  }

  // 删除所有
  removeAll () {
    for (const index in GET_ALL_KEY) {
      const key = GET_ALL_KEY[index];
      this.removeStorage(key);
    }
  }
}

export default Storage;
