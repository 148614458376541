import BaseModel from '@/libs/base/dataModel/BaseModel';

export class UserDataModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 用户手机号
    this.tel = '';
    // 充电币余额-总余额
    this.totalMoney = '';
    // 充电币余额-充值余额
    this.rechargeMoney = '';
    // 充电币余额-活动余额 // 赠送赠送
    this.giftMoney = '';
    // 在用电卡数量
    this.cardNum = '';
    // 用户编码
    this.customerCode = '';
    // 迁入金额
    this.migrationMoney = '';
    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.tel;
    return this;
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }
}
