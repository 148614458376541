/**
 * storage-所有Key
 * 外部使用：import {KEY_TOKEN, ...其他} from '@/libs/storage/keys'
 *
 * @date:2021/11/08
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

import GLOBAL_CONFIG from '@/app.config.js';

// 用户头像下标
export const KEY_ANALYSIS = `__ANALYSIS__${GLOBAL_CONFIG.env.toUpperCase()}__`;

// 用户头像下标
export const KEY_AVATAR = `__AVATAR__${GLOBAL_CONFIG.env.toUpperCase()}__`;
// 主题
export const KEY_THEME = `__THEME__${GLOBAL_CONFIG.env.toUpperCase()}__`;
// 前端全局设置
export const KEY_GLOBAL_SETTING = `__GLOBAL_SETTING__${GLOBAL_CONFIG.env.toUpperCase()}__`;
export const KEY_AUTO_CONFIG = `__AUTO_CONFIG__${GLOBAL_CONFIG.env.toUpperCase()}__`;
// token
export const KEY_TOKEN = `__TOKEN__${GLOBAL_CONFIG.env.toUpperCase()}__`;
// 曾经登录过得账号列表(快速切换账号用)
export const KEY_ADMIN_LIST = `__LOGIN_LIST__${GLOBAL_CONFIG.env.toUpperCase()}__`;
/**
 * 用户信息(登录人信息)
 * 存储位置：在login.js中->登录成功后保存
 */
export const KEY_USER = `__USER_INFO__${GLOBAL_CONFIG.env.toUpperCase()}__`;
/**
 * 用户信息(商户信息，如果登录人是服务商-存的就是服务商信息，如果登录人是设备商-存的是设备商信息，以此类推)
 * 存储位置：在login.js中->登录成功后保存
 */
export const KEY_MERCHANT = `__MERCHANT_INFO__${GLOBAL_CONFIG.env.toUpperCase()}__`;

// 菜单权限
export const KEY_LEFT_MENU = `__LEFT_MENU__${GLOBAL_CONFIG.env.toUpperCase()}__`;

export const KEY_SAVE_PASSWORD = `__SAVE_PASSWORD_${GLOBAL_CONFIG.env.toUpperCase()}__`;

// 这个账号密码的key不要起的那么明显,起一个无关紧要的
export const KEY_USERNAME_PASSWORD = `__HELLO_WORLD_${GLOBAL_CONFIG.env.toUpperCase()}__`;

// 友电高级参数
export const KEY_YOU_DEVICE_PARAMS = `__YOU_DEVICE_PARAMS_${GLOBAL_CONFIG.env.toUpperCase()}__`;

// 数据统计里的表格列控制
export const KEY_DATA_ANALYSIS_COLUMN_KEY = `__DATA_ANALYSIS_COLUMN_${GLOBAL_CONFIG.env.toUpperCase()}__`;

// 所有的key
export const GET_ALL_KEY = [
  KEY_ANALYSIS, KEY_AUTO_CONFIG, KEY_YOU_DEVICE_PARAMS,
  KEY_AVATAR, KEY_THEME, KEY_GLOBAL_SETTING, KEY_TOKEN,
  KEY_USER, KEY_SAVE_PASSWORD, KEY_USERNAME_PASSWORD, KEY_LEFT_MENU, KEY_MERCHANT, KEY_ADMIN_LIST, KEY_DATA_ANALYSIS_COLUMN_KEY];
