<!--
    Created by 程雨喵'mac on 2024/7/15.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：订单详情-订单信息
-->
<style lang="less">
.order-detail-charts {
  &.order-detail-charts-is-show-header {
    padding: @containerGap;
  }
  .charts-box-outer {
    padding: @containerGap;
    background-color: @backColorNormal;
    border-radius: @borderRadiusMin;
    .charts-box {
      .box-header-line {
        position: relative;
        .top-border {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 1px;
          background: repeating-linear-gradient(to right, @lineColorNormal, @lineColorNormal 8px, transparent 8px, transparent 12px);
        }
        &::before, &::after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: @backColorNormal;
          z-index: 1;
        }
        &::before {
          top: -10px;
          left: -10px;
        }
        &::after {
          top: -10px;
          right: -10px;
        }
      }
      .total-charts {
        padding: @containerGap;
        height: 340px;
        background-color: @backColorStrong;
        box-sizing: border-box;
        border-radius: @borderRadiusMin;
      }
      .every-charts {
        padding: @containerGap * 0.5 @containerGap;
        display: flex;
        justify-content: space-between;
        background-color: @backColorStrong;
        border-radius: @borderRadiusMin;
        .charts-item {
          .flex-grow(32%);
          height: 140px;
        }
      }
      .ele-box {
        padding: @containerGap;
        background-color: @backColorStrong;
        border-radius: @borderRadiusMin;
        .ele-table {
          .ele-tb-top {
            text-align: center;
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            background-color: @backColorLight;
            border-bottom: 1px solid @lineColorNormal;
          }
          .tb-box {
            position: relative;
            display: flex;
            font-size: 10px;
            padding: 0 2px 2px 40px;
            border-radius: @borderRadiusMin;
            overflow: hidden;
            background-color: @backColorLight;
            .tb-col {
              .flex-grow(12.5%);
              .tb-label {
                text-align: center;
                line-height: 16px;
                height: 16px;
                font-weight: 600;
              }
              .tb-value {
                padding: 2px;
                .value-b {
                  position: relative;
                  .display-flex();
                  flex-direction: column;
                  height: 45px;
                  line-height: 12px;
                  background-color: @backColorStrong;
                  border-radius: @borderRadiusMin @borderRadiusMin 0 0;
                  &+.value-b {
                    margin-top: 2px;
                    border-radius: 0 0 @borderRadiusMin @borderRadiusMin;
                  }
                  &.no-data {
                    background-color: @backColorNormal;
                  }
                  .text-light {
                    color: @textColorLight;
                  }
                }
              }
            }
            .left-time-range {
              position: absolute;
              width: 40px;
              left: 0;
              top: 0;
              min-width: 0;
              .tb-label {
                font-weight: 400;
                color: @textColorLight;
              }
              .tb-value {
                padding: 2px;
                .value-b {
                  background-color: @backColorLight;
                }
              }
            }
          }
        }
      }
    }
  }
}
.detail-charts-ele-pop-tip {
  .pop-box {
    font-size: 11px;
    &+.pop-box {
      margin-top: @containerGap * 0.5;
      padding-top: @containerGap * 0.5;
      border-top: 1px dashed #ebebeb;
    }
    .pop-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .l {
      .flex-grow(50px);
      text-align: right;
      color: @textColorLight;
    }
    .r {
      flex: 1;
      text-align: right;
      padding-left: @containerGap;
      color: @textColorStrong;
    }
  }
}
</style>

<template>
  <div
    class="order-detail-charts"
    :class="showHeader ? 'order-detail-charts-is-show-header' : ''"
  >
    <div
      v-if="showHeader"
      class="display-flex display-flex-h-between"
    >
      <p class="h3 margin-primary-bottom">
        订单图表
      </p>
      <yg-button
        type="adorn"
        size="small"
        @click="_clickRefresh"
      >
        刷新图表
      </yg-button>
    </div>
    <div class="charts-box-outer">
      <!--下面的图表-->
      <div class="charts-box">
        <!--三图汇总-->
        <div class="total-charts">
          <div
            id="growUp-total"
            style="width: 100%; height: 100%"
          />
        </div>
        <!--单个每项-->
        <div class="every-charts box-header-line">
          <div class="top-border" />
          <div
            v-for="(item, i) in tabItems"
            :key="'c-' + i"
            class="charts-item"
          >
            <div
              :id="'growUp-' + item.key"
              :style="`width: 100%; height: 100%`"
              class="charts-inner"
            />
          </div>
        </div>
        <!--订单时段电量-->
        <div
          v-if="hasElectric"
          class="ele-box box-header-line"
        >
          <div class="top-border" />
          <div class="ele-table">
            <div class="ele-tb-top">
              仅电量计费模式记录时段数据
            </div>
            <div
              v-for="(htmlOuterArr, o) in model.electricityInfo.tableHeader"
              :key="'o-' + o"
              class="tb-box"
            >
              <!--右侧00分，30分展示-->
              <div class="tb-col left-time-range">
                <div class="tb-label">
                  时段
                </div>
                <div class="tb-value">
                  <div class="value-b">
                    <div>00:00</div>
                    <div>至</div>
                    <div>29:59</div>
                  </div>
                  <div class="value-b">
                    <div>30:00</div>
                    <div>至</div>
                    <div>59:59</div>
                  </div>
                </div>
              </div>
              <!--表头-->
              <div
                v-for="(item, i) in htmlOuterArr"
                :key="'t-h-' + i"
                class="tb-col"
              >
                <div class="tb-label">
                  {{ item }}点
                </div>
                <div class="tb-value">
                  <div
                    v-for="(tPack, tt) in eleTPack"
                    :key="'t-p-' + tt"
                    class="value-b"
                    :class="model.electricityInfo.tableRow[item][tPack.key] ? '' : 'no-data'"
                  >
                    <Poptip
                      v-if="model.electricityInfo.tableRow[item][tPack.key]"
                      trigger="hover"
                      transfer
                    >
                      <div>
                        <div class="text-light">
                          已充{{ model.electricityInfo.tableRow[item][tPack.key].differenceOfTime }}分钟
                        </div>
                        <div>最高{{ model.electricityInfo.tableRow[item][tPack.key].power }}W</div>
                        <div>使用{{ model.electricityInfo.tableRow[item][tPack.key].periodCapacity }}度</div>
                      </div>
                      <div
                        slot="title"
                        style="font-weight: 600"
                      >
                        {{ item }}点{{ tPack.range }}
                      </div>
                      <div
                        slot="content"
                        class="detail-charts-ele-pop-tip"
                      >
                        <div
                          v-for="(tItem, tIndex) in model.electricityInfo.tableRow[item][tPack.key].items"
                          :key="'t-index-' + tIndex"
                          class="pop-box"
                        >
                          <div
                            v-for="(popRow, pp) in eleTPackInfoItems"
                            :key="'pp-' + pp"
                            class="pop-row"
                          >
                            <div class="l">
                              {{ popRow.label }}
                            </div>
                            <div class="r">
                              {{ tItem[popRow.key] }}
                              <span v-if="popRow.unit">{{ popRow.unit }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Poptip>
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DATA_COLOR } from '@/pages/300__board/dataAnalysis/utils/colors';
const echarts = require('echarts');

export default {
  components: {},
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      timeRangeOption: [],
      tabItems: [
        { label: '功率走势', shortLabel: '', key: 'powerInfos', unit: 'W', color: 'primary' },
        { label: '电压走势', shortLabel: '', key: 'voltageInfos', unit: 'V', color: 'light' },
        { label: '电量走势', shortLabel: '', key: 'electricities', unit: '度', color: 'pink' }
      ],
      chartsItems: [],
      myChart: null,
      eleTPack: [
        { key: 'top', range: '00:00~29:59' },
        { key: 'bottom', range: '30:00~59:59' }
      ],
      eleTPackInfoItems: [
        { label: '上送时间：', key: 'time' },
        { label: '已充时长：', key: 'differenceOfTime', unit: '分钟' },
        { label: '最高功率：', key: 'power', unit: 'W' },
        { label: '时段电量：', key: 'periodCapacity', unit: '度' },
        { label: '总电量：', key: 'electricity', unit: '度' }
      ]
    };
  },
  computed: {
    hasElectric () {
      return this.model.electricityInfo && this.model.electricityInfo.orgDataList && this.model.electricityInfo.orgDataList.length;
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.drawCharts);
  },
  created () {
    window.addEventListener('resize', this.drawCharts);
    this.$nextTick(() => {
      this.myChart = echarts.init(document.getElementById('growUp-total'));
      this.myChart.setOption(this.getTotalGrowUpOption());
      setTimeout(() => {
        const chartsItems = [];
        this.tabItems.forEach((v, index) => {
          const c = echarts.init(document.getElementById('growUp-' + v.key));
          c.setOption(this.getGrowUpOption(v, index));
          chartsItems.push(c);
        });
        this.chartsItems = chartsItems;
      }, 300);
    });
    // console.log(this.model.electricityInfo);
  },
  methods: {
    drawCharts () {
      if (this.chartsItems.length) {
        this.chartsItems.forEach(v => {
          v.resize();
        });
      }
      this.myChart.resize();
    },
    // 左侧三合一
    getTotalGrowUpOption () {
      const series = [];
      this.tabItems.forEach((v, index) => {
        const data = [];
        for (let i = 0; i < this.model.yList.length; i++) {
          const arr = this.model.yList[i];
          data.push(arr[index]);
        }
        series.push({
          name: v.label.replace('走势', ''),
          yAxisIndex: index < 2 ? 0 : 1,
          type: 'line',
          lineStyle: {
            type: 'solid',
            width: 1
          },
          // stack: 'total',
          sampling: 'lttb',
          symbolSize: 0,
          smooth: true,
          itemStyle: {
            color: DATA_COLOR[v.color]
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: false,
            color: 'black',
            position: 'top',
            fontSize: 8
          },
          data: data
        });
      });
      const op = {
        title: {
          text: '订单图表三合一',
          left: 'center',
          top: '0',
          textStyle: {
            fontSize: 20
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          trigger: 'axis',
          formatter: (r) => {
            const chart0 = r[0];
            const dataIndex = chart0.dataIndex;
            const data = this.model.orgDataList[dataIndex];
            const yArr = this.model.yList[dataIndex];
            let str = '';
            if (data) {
              str = `
                <p style="font-weight: 600;margin-bottom: 3px;font-size: 13px;">订单图表三合一</p>
                已充时间：${data.differenceOfTime}分钟<br/>
                当前时间：${data.time}
              `;
              this.$emit('on-trigger', data.time);
            }
            this.tabItems.forEach((v, index) => {
              str += `<p style="font-weight:600;color: ${DATA_COLOR[v.color]}">当前${r[index].seriesName}：${yArr[index]}${v.unit}${index === 2 && data.isCalelectricity ? '(此条电量数据仅供参考)' : ''}</p>`;
            });
            return `<div style="font-size: 11px">${str}</div>`;
          },
          alignTicks: true,
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: 'rgba(255,51,95,1)'
            }
          }
        },
        grid: {
          left: '0%',
          right: '0',
          bottom: '0',
          top: '50px',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: this.model.xList,
            axisLabel: {
              fontSize: 10 // 设置字体大小为16
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            min: 0,
            boundaryGap: [0, 0.1],
            axisLabel: {
              fontSize: 10,
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            scale: true,
            min: 0,
            boundaryGap: [0, 0.05],
            show: false,
            splitLine: {
              show: false
            },
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: series
      };
      return op;
    },
    // 每项
    getGrowUpOption (option, index) {
      const xList = this.model.xList;
      const yList = [];
      this.model.yList.forEach(vvv => {
        yList.push(vvv[index]);
      });
      const op = {
        title: {
          text: option.label,
          fontSize: 10,
          left: 'center',
          top: '0',
          textStyle: {
            fontSize: 10
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          trigger: 'axis',
          formatter: (r) => {
            const chart0 = r[0];
            const dataIndex = chart0.dataIndex;
            const data = this.model.orgDataList[dataIndex];
            let str = '';
            if (data) {
              str = `
                <p style="font-weight: 600;margin-bottom: 3px;font-size: 13px;">${chart0.seriesName}</p>
                已充时间：${data.differenceOfTime}分钟<br/>
                当前时间：${data.time}<br/>
                当前值：${chart0.value}${index === 2 && data.isCalelectricity ? '(此条电量数据仅供参考)' : ''}
              `;
            }
            // this.tabItems.forEach((v, index) => {
            //   str += `<p style="font-weight:600;color: ${DATA_COLOR[v.color]}">当前${r[index].seriesName}：${yArr[index]}</p>`;
            // });
            return `<div style="font-size: 11px">${str}</div>`;
          },
          alignTicks: true,
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: 'rgba(255,51,95,1)'
            }
          }
        },
        grid: {
          left: '0%',
          right: '0',
          bottom: '0',
          top: '20px',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: xList.length ? xList : this.model.xList,
            axisLabel: {
              fontSize: 10 // 设置字体大小为16
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            min: 0,
            boundaryGap: [0, 0.1],
            axisLabel: {
              fontSize: 10,
              formatter: '{value}' + option.unit
            }
          }
        ],
        series: [
          {
            name: option.label,
            type: 'line',
            lineStyle: {
              // type: 'solid',
              type: 'dashed',
              width: 1
            },
            // stack: 'total',
            sampling: 'lttb',
            symbolSize: 0,
            smooth: true,
            itemStyle: {
              color: DATA_COLOR[option.color]
            },
            areaStyle: {
              // 对应右/下/左/上四个方位，而0 0 0 1则代表渐变色从正上方开始
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: DATA_COLOR[option.color + 'Alpha'] },
                { offset: 1, color: DATA_COLOR[option.color + 'AlphaDeep'] }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            label: {
              show: false,
              color: 'black',
              position: 'top',
              fontSize: 8
            },
            data: yList.length ? yList : [0]
          }
        ]
      };
      return op;
    },
    _clickTopTab (i) {
      this.tabItems[i].isSel = !this.tabItems[i].isSel;
      setTimeout(() => {
        this.drawCharts();
      }, 50);
    },
    _clickRefresh () {
      this.$emit('on-refresh');
    }
  }
};
</script>
