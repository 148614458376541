import BaseModel from '@/libs/base/dataModel/BaseModel';

function timeFormatUtil (time) {
  let s = time + '';
  s = parseInt(s);
  if (s > 24) {
    s -= 24;
  }
  if (s === 24) {
    s = 0;
  }
  if (s < 10) {
    s = '0' + s;
  }
  return s + '';
}

export class ChartsElectricityInfoModel extends BaseModel {
  constructor () {
    super();
    // 12列，起始时间从数据里的第一个元素拿，后面的自己补位
    this.tableHeader = [];
    // key 为时间，value为对象
    this.tableRow = {};
    this.orgDataList = [];
  }

  initData (resData) {
    const tableHeader = [];
    const tableRow = {};
    resData.forEach((v, index) => {
      const time = v.time;
      const tArr = time.split(' ')[1];
      const t1Arr = tArr.split(':');
      // 时间
      const t = t1Arr[0];
      // 时间段
      const r = t1Arr[1];
      if (index === 0) {
        // 第一个的时候就把header确定下来 + tableRow结构定下来
        const start = parseInt(t);
        for (let i = 0; i < 24; i++) {
          const headerT = timeFormatUtil(start + i);
          tableHeader.push(headerT);
          tableRow[headerT] = {
            top: null,
            bottom: null
          };
        }
      }
      // 确定时段，是00 还是 30
      const isTop = parseInt(r) < 30;
      const lastValue = tableRow[timeFormatUtil(t)][isTop ? 'top' : 'bottom'];
      if (lastValue) {
        // 在同一个时段内，时间
        lastValue.periodCapacity += v.periodCapacity;
        lastValue.periodCapacity = Number(lastValue.periodCapacity.toFixed(6));
        const tempL = lastValue.power.replace('W', '');
        const tempV = v.power.replace('W', '');
        // 在同一个时段内，展示更大的功率
        lastValue.power = Number(tempL) > Number(tempV) ? lastValue.power : v.power;
        // 在同一个时段内，已充时长取大的
        lastValue.differenceOfTime = lastValue.differenceOfTime > v.differenceOfTime ? lastValue.differenceOfTime : v.differenceOfTime;
        lastValue.items.push(v);
      } else {
        const vvv = { ...v };
        vvv.items = [v];
        tableRow[timeFormatUtil(t)][isTop ? 'top' : 'bottom'] = vvv;
      }
    });
    if (resData.length < 16) {
      this.tableHeader = [tableHeader.slice(0, 8)];
    } else if (resData.length < 32) {
      this.tableHeader = [tableHeader.slice(0, 8), tableHeader.slice(8, 16)];
    } else {
      this.tableHeader = [tableHeader.slice(0, 8), tableHeader.slice(8, 16), tableHeader.slice(16, 24)];
    }
    this.tableRow = tableRow;
    this.orgDataList = resData;
    return this;
  }
}

export class ChargingBusinessChartsModel extends BaseModel {
  constructor () {
    super();
    // 订单时段电量列表（电费计费模式下有）
    this.electricityInfo = null;

    // 三项合一
    this.xList = [];
    // [[0, 0, 0]] 第一个是功率，第二个是电压，第三个电量
    this.yList = [];
    // 三项合一源数据
    this.orgDataList = [];
  }

  initData (resData) {
    super.initData(resData);
    const hasPower = resData.powerInfos && Array.isArray(resData.powerInfos) && resData.powerInfos.length;
    if (hasPower) {
      const len = resData.powerInfos.length;
      // 记录末次电压
      let lastVol = 0;
      // 计算电量
      let eleSum = 0;
      // 计算电量中使用
      let eleLastTime = 0;
      for (let i = 0; i < len; i++) {
        const pDic = resData.powerInfos[i];
        const time = pDic.time;
        const tArr = time.split(' ')[1];
        const t1Arr = tArr.split(':');
        const xTime = t1Arr[0] + ':' + t1Arr[1];
        this.xList.push(xTime);
        // 功率
        let p = 0;
        if (pDic.power) { p = pDic.power; }
        // 电压
        let v = 0;
        if (pDic.voltage) {
          v = pDic.voltage;
          lastVol = pDic.voltage;
        } else {
          v = lastVol;
        }
        // 电量
        let e = 0;
        if (pDic.electricity) {
          e = Number(pDic.electricity);
        } else {
          let pNumber = Number(pDic.power);
          if (Number.isNaN(pNumber) || pNumber < 0) {
            pNumber = 0;
          }
          pDic.isCalelectricity = true;
          const difTime = pDic.differenceOfTime - eleLastTime;
          eleLastTime = pDic.differenceOfTime;
          const eCal = Number((pNumber) / 1000 * difTime / 60);
          eleSum = Number((eleSum + eCal).toFixed(6));
          // 计算出来
          pDic.electricity = eleSum;
          e = eleSum;
        }
        this.yList.push([p, v, e]);
        this.orgDataList.push(pDic);
      }
    }

    const hasEle = resData.electricityInfo && Array.isArray(resData.electricityInfo) && resData.electricityInfo.length;
    if (hasEle) {
      this.electricityInfo = ChartsElectricityInfoModel.initModelWithData(resData.electricityInfo);
    }

    return this;
  }
}
