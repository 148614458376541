/**
 * @date:2024/01/23
 * @author 王丽莎
 * @copyright 南京云柜<yun-gui.com>
 * 点位管理
 */

import BaseApi from '@/request/base/BaseApi';

class LocationApiSet {
  constructor () {
    this.getSiteListApi = BaseApi.createModel('点位信息列表', '/opmp/site/list', true).setMethod();
    this.querySiteListWithOfficeApi = BaseApi.createModel('获取点位信息列表（含办事处）', '/opmp/site/querySiteListWithOffice', true).setMethod(1);
    this.addSiteApi = BaseApi.createModel('新增点位', '/opmp/site/add', true);
    this.getSiteInfoApi = BaseApi.createModel('获取点位信息', '/opmp/site/info', true).setMethod(0, false);
    this.getOperationInfoApi = BaseApi.createModel('获取点位信息', '/opmp/site/operation/get', true);
    this.editSiteAddressApi = BaseApi.createModel('设置点位地址', '/opmp/site/edit', true);
    this.editSiteContactApi = BaseApi.createModel('设置点位联系人', '/opmp/site/manager/set', true);
    this.editSiteOprationApi = BaseApi.createModel('设置点位运营', '/opmp/site/operation/set', true);
    this.changeStatusApi = BaseApi.createModel('启用/禁用点位', '/opmp/site/changeStatus', true).setMethod(2);
    this.getDeviceCountApi = BaseApi.createModel('获取点位设备数量', '/opmp/site/deviceCount', true).setMethod(0, false);
  }
}
export default new LocationApiSet();
