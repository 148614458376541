<!--
    Created by 程雨喵'mac on 2024/9/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：按电费计费
-->
<style lang="less">
.device-price-use-electric {
  .ivu-alert-with-desc {
    padding: @containerGap * 0.5 @containerGap;
    .ivu-alert-message {
      font-size: 13px;
      //font-weight: 600;
    }
    .ivu-alert-desc {
      font-size: 12px;
      line-height: 18px;
    }
  }
  .type-tit {
    padding-bottom: @containerGap;
    font-weight: 600;
    color: @textColorLight;
  }
  .money-rules-table {
    border: 1px solid @lineColorNormal;
    border-bottom: 0;
    border-radius: @borderRadiusMin;
    .money-rules-table-row {
      display: flex;
      //align-items: flex-start;
      border-bottom: 1px solid @lineColorNormal;
      line-height: @formHeight;
      font-size: 11px;
      &.is-header {
        background-color: @backColorNormal;
        color: @textColorLight;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        .table-col {
          padding: 0;
          &.col-t-2 {
            .header-col-inner {
              padding: 5px;
            }
          }
          .col-inner-merge {
            border-top: 1px solid @lineColorNormal;
            font-size: 11px;
            .merge-col {
              padding: 3px;
            }
          }
        }
      }
      .table-col {
        .display-flex();
        flex-wrap: wrap;
        .light-text {
          font-size: 10px !important;
          color: @textColorLight;
        }
        .table-col-inner {
          width: 100%;
          .display-flex();
          white-space: nowrap;
          .ivu-input-number {
            padding-right: 0;
            .flex-grow(70px);
            .ivu-input-number-handler-wrap {
              display: none;
            }
          }
        }
        .col-inner-merge {
          display: flex;
          .flex-grow(100%);
          .merge-col {
            padding: 5px;
            .display-flex();
            .flex-grow(50%);
            &+.merge-col {
              border-left: 1px solid @lineColorNormal;
            }
          }
        }
        &+.table-col {
          border-left: 1px solid @lineColorNormal;
        }
        &.col-t-0 {
          .flex-grow(100px);
          font-weight: 600;
          color: @textColorLight;
        }
        &.col-t-1 {
          padding: 0 5px;
          flex: 1;
        }
        &.col-t-2 {
          .flex-grow(220px);
        }
        &.col-t-3 {
          .flex-grow(80px);
        }
      }
    }
  }
  .add-btn-row {
    position: relative;
    .table-col {
      padding: 5px;
    }
    .add-btn {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .display-flex();
      border: 1px dashed @themeColor;
      background-color: rgba(255,255,255,0.7);
      color: @themeColor;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
    }
  }
  @keyframes errorAmin {
    0% { background-color: transparent }
    50% { background-color: rgba(254,85,113,0.2) }
    100% { background-color: transparent }
  }
  .row-is-error {
    animation: errorAmin 0.5s;
    animation-iteration-count: 3;
  }
  .b-edit-row {
    .display-flex();
    justify-content: flex-start;
    font-size: 12px;
    .ivu-input-number {
      margin: 0 5px;
      padding-right: 0;
      .flex-grow(80px);
      .ivu-input-number-handler-wrap {
        display: none;
      }
    }
    .ivu-select {
      margin: 0 5px;
      padding-right: 0;
      .flex-grow(80px);
    }
  }
  .b-look-box {
    font-size: 12px;
    line-height: 18px;
  }
}
</style>

<template>
  <div class="device-price-use-electric">
    <div class="type-tit">
      <span v-if="isEdit">设置</span>电费及电量服务费
    </div>
    <div
      v-if="!!dataList.length"
      class="money-rules-table"
    >
      <!--头部-->
      <div class="money-rules-table-row is-header">
        <div
          v-for="(item, i) in tableHeaders"
          :key="i"
          class="table-col"
          :class="'col-t-' + i"
        >
          <div class="header-col-inner">
            {{ item }}
          </div>
          <div
            v-if="i === 2"
            class="col-inner-merge"
          >
            <div class="merge-col">
              电费
            </div>
            <div class="merge-col">
              服务费
            </div>
          </div>
        </div>
      </div>
      <!--实际内容-->
      <div
        v-for="(item, i) in dataList"
        :key="'r-' + i"
        class="money-rules-table-row"
        :class="errorIndex === i ? 'row-is-error' : ''"
      >
        <div class="table-col col-t-0">
          时段{{ item.level }}
        </div>
        <div class="table-col col-t-1">
          <div
            v-if="isEdit"
            class="table-col-inner"
          >
            <span
              v-if="i !== 0 && checkIsNextDay(item.h5RangeMin)"
              style="margin-right: 5px"
              class="light-text"
            >次日</span>
            <Select
              v-model="item.h5RangeMin"
              @on-change="onTimeChange"
            >
              <Option
                v-for="(op, p) in timeOptions"
                :key="p + '-op'"
                :value="op.value"
                :disabled="i === 0 && op.value === '24:00'"
              >
                {{ op.label }}
              </Option>
            </Select>
            <span style="margin: 0 5px">~</span>
            <span
              v-if="checkIsNextDay(item.h5RangeMax)"
              style="margin-right: 5px"
              class="light-text"
            >次日</span>
            <!--最后一个档位的最后一位，不可以编辑-->
            <Select
              v-model="item.h5RangeMax"
              :disabled="i === (dataList.length - 1)"
              @on-change="onTimeChange"
            >
              <Option
                v-for="(op, p) in timeOptions"
                :key="p + '-op'"
                :value="op.value"
              >
                {{ op.label }}
              </Option>
            </Select>
          </div>
          <div
            v-else
            class="table-col-inner"
          >
            {{ item.h5RangeMin }}点至{{ item.h5RangeMax }}点之间
          </div>
        </div>
        <div
          class="table-col col-t-2"
        >
          <div
            v-if="isEdit"
            class="table-col-inner"
          >
            <div
              class="col-inner-merge"
            >
              <div class="merge-col">
                <InputNumber
                  v-model="item.chargePrice"
                  :min="0"
                />
                <span
                  style="margin-left: 5px"
                  class="light-text"
                >元/度</span>
              </div>
              <div class="merge-col">
                <InputNumber
                  v-model="item.servicePrice"
                  :min="0"
                />
                <span
                  style="margin-left: 5px"
                  class="light-text"
                >元/度</span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="table-col-inner"
          >
            <div
              class="col-inner-merge"
            >
              <div class="merge-col">
                {{ item.chargePrice }}
                <span style="margin-left: 5px">元/度</span>
              </div>
              <div class="merge-col">
                {{ item.servicePrice }}
                <span style="margin-left: 5px">元/度</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isEdit"
          class="table-col col-t-3"
        >
          <yg-button
            type="text"
            disabled-text="此项暂时无法删除"
            :disabled="i === 0"
            @click="_clickDel(i)"
          >
            删除
          </yg-button>
        </div>
      </div>
      <!--最底部新增-->
      <div
        v-if="isEdit && dataList.length < 10"
        class="money-rules-table-row add-btn-row"
        @click.stop="_clickAdd"
      >
        <div class="table-col col-t-0">
          时段{{ dataList.length + 1 }}
        </div>
        <div class="table-col col-t-1">
          -
        </div>
        <div class="table-col col-t-2">
          -
        </div>
        <div class="table-col col-t-3">
          -
        </div>
        <div class="add-btn">
          - 增加时段{{ dataList.length + 1 }} -
        </div>
      </div>
    </div>
    <div
      v-else
      class="__g-no-data"
      style="height: 300px;"
    />
    <Alert
      v-if="isEdit"
      class="margin-primary-top"
    >
      说明
      <template slot="desc">
        如电量服务费为0，则不收电量服务费，同时设置时长服务费和电量服务费则同时收取。
      </template>
    </Alert>
    <div
      class="type-tit padding-primary-top"
    >
      <span v-if="isEdit">设置</span>时长服务费
    </div>
    <div
      v-if="isEdit"
      class="b-edit-row"
    >
      <span>时长服务费单价</span>
      <InputNumber
        v-model="model.timeServicePrice"
        :min="0"
      />
      <span>元/小时，保底时长</span>
      <Select
        v-model="model.minServiceTime"
      >
        <Option
          v-for="(op, p) in minServiceTimeOptions"
          :key="p + '-op'"
          :value="op.value"
        >
          {{ op.label }}
        </Option>
      </Select>
      <span>小时，超出后按分钟计收服务费。</span>
    </div>
    <div
      v-else
      class="b-look-box"
    >
      <span>{{ model.h5TimeServicePrice }}</span>
    </div>
    <Alert
      v-if="isEdit"
      class="margin-primary-top"
    >
      说明
      <template slot="desc">
        <p>1、如同时设置时长服务费和电量服务费则两者同时收取。</p>
        <p>2、如时长服务费单价为0，则不收时长服务费，同时保底时长无效；</p>
        <p>3、如时长服务费大于0，但保底时长设为0小时，则按实际充电分钟数计收时长服务费；</p>
        <p>4、保底时长设为大于0小时，当充电时长不足保底时长时，则按【保底时长】x【时长服务费单价】计费；</p>
      </template>
    </Alert>
  </div>
</template>

<script>
import { DevicePriceModel } from '@/pages/050__deviceManager/chargingPileList/model/DevicePriceModel';
import { isFloat } from '@/libs/regularUtils';

export default {
  components: {},
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    dataList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      errorIndex: -1,
      timeOptions: [
        { value: '', label: '' }
      ],
      minServiceTimeOptions: [
        { value: 0, label: '0' },
        { value: 0.5, label: '0.5' },
        { value: 1, label: '1' }
        // { value: '-', label: '-' }
      ]
    };
  },
  computed: {
    tableHeaders () {
      return this.isEdit ? ['时段', '时段区间', '时段收费', '操作'] : ['时段', '时段区间', '时段收费'];
    }
  },
  created () {
    const timeOptions = [];
    for (let i = 0; i < 24; i++) {
      const time = i < 10 ? ('0' + i) : i;
      timeOptions.push({ value: `${time}:00`, label: `${time}:00` });
      timeOptions.push({ value: `${time}:30`, label: `${time}:30` });
    }
    timeOptions.push({ value: '24:00', label: '24:00' });
    this.timeOptions = timeOptions;
    console.log(this.model);
  },
  methods: {
    onInput () {
      this.errorIndex = -1;
    },
    onTimeChange () {
      this.errorIndex = -1;
      this.resetLastTime();
    },
    // 判断选择的日期是否为次日
    checkIsNextDay (time) {
      if (time && time.includes(':')) {
        const first = this.dataList[0].h5RangeMin;
        const s = parseInt(first.replace(':', ''));
        const e = parseInt(time.replace(':', ''));
        return e <= s;
      }
      return false;
    },
    $checkSubmit () {
      const list = this.dataList;
      let timeDurationSum = 0;
      for (let i = 0; i < list.length; i++) {
        const dic = list[i];
        // 金额校验 ----------->
        if (dic.chargePrice < 0) {
          this.errorIndex = i;
          return this.$Message.warning(`「时段${i + 1}」电费单价有误`);
        } else if (dic.servicePrice < 0) {
          this.errorIndex = i;
          return this.$Message.warning(`「时段${i + 1}」服务费单价有误`);
        } else if ((dic.chargePrice + dic.servicePrice) === 0) {
          this.errorIndex = i;
          return this.$Message.warning(`「时段${i + 1}」时段收费合计必须大于0`);
        } else if (!isFloat(dic.chargePrice, 4) || !isFloat(dic.servicePrice, 4)) {
          this.errorIndex = i;
          return this.$Message.warning(`「时段${i + 1}」金额最多支持小数点后四位`);
        }
        // 时段闭环校验 ----------->
        if (!dic.h5RangeMin.includes(':')) {
          this.errorIndex = i;
          return this.$Message.warning(`「时段${i + 1}」起始时段设置有误`);
        }
        if (!dic.h5RangeMax.includes(':')) {
          this.errorIndex = i;
          return this.$Message.warning(`「时段${i + 1}」结束时段设置有误`);
        }
        if (list.length !== 1) {
          if (dic.h5RangeMin === dic.h5RangeMax) {
            this.errorIndex = i;
            return this.$Message.warning(`「时段${i + 1}」时段区间有误`);
          } else if (i !== 0) {
            if (dic.h5RangeMin !== list[i - 1].h5RangeMax) {
              this.errorIndex = i;
              return this.$Message.warning(`「时段${i + 1}」与上一时段时间区间未闭合`);
            }
          }
        }
        // 时段闭环校验 -----------> h5TimeDuration
        const s = parseInt(dic.h5RangeMin.replace(':', ''));
        const e = parseInt(dic.h5RangeMax.replace(':', ''));
        timeDurationSum += (e - s);
        if (s > e) {
          timeDurationSum += 2400;
        }
      }
      if (timeDurationSum !== 2400) {
        return this.$Message.warning('所有时段总和，必须闭环24个小时');
      }
      this.errorIndex = -1;
      if (this.model.timeServicePrice) {
        if (!isFloat(this.model.timeServicePrice, 4)) {
          return this.$Message.warning('时长服务费单价支持0及大于0的数字，且支持到小数点后4位');
        }
      }
      return { success: true };
    },
    resetLevel () {
      setTimeout(() => {
        this.dataList.forEach((v, i) => {
          v.level = i + 1;
        });
      }, 50);
    },
    resetLastTime () {
      const first = this.dataList[0].h5RangeMin;
      const last = this.dataList[this.dataList.length - 1];
      if (first === '00:00') {
        last.h5RangeMax = '24:00';
      } else {
        last.h5RangeMax = first;
      }
      this.$set(this.dataList, this.dataList.length - 1, last);
    },
    _clickDel (i) {
      if (this.dataList.length <= 1) {
        return this.$Message.info('至少要保留1个时段');
      }
      this.dataList.splice(i, 1);
      this.resetLevel();
      this.resetLastTime();
    },
    _clickAdd () {
      this.dataList.push(DevicePriceModel.createAdditionModelByDian(this.dataList[this.dataList.length - 1]));
      this.resetLevel();
      this.resetLastTime();
    }
  }
};
</script>
