<!--
    Created by 程雨喵'mac on 2024/7/15.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：订单详情-订单指令记录
-->
<style lang="less">
.order-detail-log {
  position: relative;
  padding: 0 @containerGap @containerGap @containerGap;
  &.order-detail-log-is-show-header {
    padding-top: @containerGap;
  }
  .h3 {
    display: flex;
  }
  .search-options {
    padding: @containerGap;
    margin-bottom: @containerGap;
    font-size: 14px;
    display: flex;
    white-space: nowrap;
    align-items: flex-start;
    background-color: @backColorNormal;
    border-radius: @borderRadiusMin;
    .left-label {
      color: @textColorStrong;
    }
    .ck-label {
      color: @textColorLight;
    }
  }
  .error-book {
    position: absolute;
    right: @containerGap;
    top: @containerGap;
    display: flex;
    color: @textColorLight;
    .right-box {
      display: flex;
      flex-wrap: wrap;
      .book-item {
        .display-flex();
        cursor: pointer;
        img {
          width: 14px;
        }
      }
    }
  }
  .ivu-timeline-item {
    padding: 12px 0 0;
    &.is-high-light {
      background-color: rgba(84,110,253,0.15);
    }
  }
  .time-header {
    padding-top: 2px;
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 12px;
    .t {
      display: inline-flex;
      align-items: center;
    }
    .v {
      margin-left: 8px;
      color: @textColorLight;
      font-weight: 400;
      display: inline-flex;
      align-items: center;
      @keyframes heartBeatAnim {
        0% { transform: scale(0.9)}
        50% { transform: scale(1.1)}
        100% { transform: scale(0.9)}
      }
      .heart-box {
        display: inline-flex;
        justify-content: center;;
        align-items: center;
        width: 16px;
        .ivu-icon {
          color: @errorColor;
          animation: 1s heartBeatAnim infinite;
        }
      }
    }
  }
  .time-content-li {
    line-height: 18px;
    font-size: 12px;
    word-break: break-all;
    span {
      padding: 0 3px;
      background-color: @errorColor;
      color: white;
      text-decoration: underline;
      cursor: help;
      transition: all @transitionTime15;
      &:after {
        content: '?';
        display: none;
      }
      &:hover {
        &:after {
          display: inline;
        }
      }
    }
  }
  .show-more {
    padding: 10px;
    .display-flex();
    color: @textColorLight;
    background-color: @backColorLight;
    font-size: 12px;
    border-radius: @borderRadiusMin;
    cursor: pointer;
  }
  .req-log-box {
    margin: 0 auto;
    padding: @containerGap * 2 0 @containerGap;
    .display-flex();
    flex-direction: column;
    background-color: @backColorLight;
    width: 200px;
    border-radius: @borderRadiusMid;
    img {
      width: 120px;
    }
  }
}
</style>

<template>
  <div
    class="order-detail-log"
    :class="showHeader ? 'order-detail-log-is-show-header' : ''"
  >
    <div
      v-if="showHeader"
      class="h3 margin-primary-bottom"
    >
      订单指令记录
    </div>
    <div
      v-if="showHeader"
      class="padding-primary-bottom"
    />
    <template v-if="hasRequested">
      <!--筛选项-->
      <div
        class="search-options"
      >
        <div class="left-label">
          按需查看：
        </div>
        <RadioGroup
          v-model="selectInsData"
          @on-change="onChangeCheckBox"
        >
          <Radio
            v-for="(item, i) in selectOp"
            :key="i"
            :label="item.value"
          >
            <span class="ck-label">{{ item.label }}</span>
          </Radio>
        </RadioGroup>
      </div>
    </template>
    <template v-if="dataList.length">
      <Timeline>
        <TimelineItem
          v-for="(item, i) in dataList"
          :id="item.createTime"
          :key="i"
          :class="item.createTime === highLightTime ? 'is-high-light' : ''"
        >
          <p class="time-header">
            <span class="t">
              {{ item.createTime }}
            </span>
            <span class="v">{{ item.instructionName }}</span>
            <span class="v">(指令:{{ item.instructionType }}
              <span
                v-if="item.h5IsHeartBeat"
                class="heart-box"
              >
                <Icon
                  type="md-heart"
                />
              </span>)</span>
          </p>
          <div>
            <div
              class="time-content-li"
              @click="_clickLog(item)"
            >
              <p v-html="item.instructionInfo" />
            </div>
          </div>
        </TimelineItem>
      </Timeline>
      <div
        v-if="!page.isLastPage"
        class="show-more"
        @click.stop="_clickMore"
      >
        点击加载更多({{ dataList.length }}/{{ page.total }}条)
      </div>
      <div
        v-else-if="dataList.length && page.isLastPage"
        class="show-more"
      >
        已经到底了({{ dataList.length }}/{{ page.total }}条)
      </div>
    </template>
    <div
      v-else-if="!hasRequested"
      class="req-log-box"
    >
      <img
        src="/bg/requestLogImg.png"
        alt=""
      >
      <yg-button
        type="text"
        @click="_clickRequestData"
      >
        点击拉取日志
      </yg-button>
    </div>
    <div
      v-else
      class="__g-no-data"
      style="height: 300px"
    />
  </div>
</template>

<script>
import OrderApiSet from '@/request/api/OrderApiSet';
import {
  ChargeLogItemModel
} from '@/pages/060__rechargeManager/chargingBusinessOrder/model/ChargLogDataModel';

export default {
  components: {},
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    highLightTime: {
      type: String,
      default: ''
    },
    autoRequest: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      hasRequested: false,
      // 需展示的列表
      dataList: [],
      // 指令范围
      selectOp: [
        { label: '不看心跳指令', value: 0 },
        { label: '查看全部指令(包含心跳)', value: 1 }
      ],
      // 选择展示的指令
      selectInsData: 0,
      page: {
        current: 1,
        size: 30,
        isLastPage: false,
        total: 0
      }
    };
  },
  created () {
    if (this.autoRequest) {
      this.requestData();
    }
  },
  methods: {
    onChangeCheckBox () {
      this.page.current = 1;
      this.dataList = [];
      this.requestData();
    },
    _clickRequestData () {
      this.requestData();
    },
    _clickMore () {
      this.page.current += 1;
      this.requestData();
    },
    _clickLog (item) {
      if (item.h5HighlightText) {
        this.$Modal.confirm({
          title: '温馨提示',
          content: '是否打开帮助' + item.h5HighlightText + '帮助文档?',
          onOk: () => {
            window.open('https://docs.qq.com/doc/p/df2a027e9907971056452226b7fdabfbc9ee86cb');
          }
        });
      }
    },
    requestData () {
      const api = OrderApiSet.getOrderDetailLog;
      const p = this.page;
      api.params = {
        pageNo: p.current,
        pageSize: p.size,
        orderNo: this.model.orderInfo ? this.model.orderInfo.orderNo : this.model.orderNo,
        heartBeat: this.selectInsData
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.dataList = [...this.dataList, ...ChargeLogItemModel.initListWithDataList(resData.rows)];
        this.page.isLastPage = resData.total <= this.dataList.length;
        this.page.total = resData.total;
        this.hasRequested = true;
      });
    }
  }
};
</script>
