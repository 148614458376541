import BaseModel from '@/libs/base/dataModel/BaseModel';

export class ChargingCoinTransactionRecordDataModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 用户手机号
    this.tel = '';
    // 交易流水号
    this.transSerialNo = '';
    // 交易时间
    this.transTime = '';
    // 动账类型
    this.transType = '';
    // 动账类型：1-入账 2-出账 3-冻结
    this.transTypeDesc = '';
    // 动账分类
    this.transClassify = '';
    // 动账分类：1-购买充电币 2-迁入余额 3-充电支付 4-充电退款 5-退币
    this.transClassifyDesc = '';
    // 充电业务类型
    this.bizType = '';
    // 充电业务类型：1-小程序充电 2-刷卡充电
    this.bizTypeDesc = '';
    // 金额
    this.payMoney = '';
    // 交易科目
    this.transCourse = '';
    // 交易后科目余额
    this.transCourseBalance = '';
    // 关联业务订单号
    this.bizOrderNo = '';
    // 用户金额记录表ID
    this.moneyRecordId = '';
    // 展示扣减充电币按钮（0-不展示 1-展示）
    this.showCoinReduce = '';
    // 展示扣减记录按钮（0-不展示 1-展示）
    this.showCoinRecord = '';
    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.transSerialNo;
    return this;
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const transTypeDesc = ['收入', '支出'];
    const transCourseDesc = ['充值金额', '活动金额'];// 赠送赠送
    const transClassifyDesc = ['购买充电币', '迁入余额', '充电支付', '充电退款', '退币'];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        id: '000101010' + i,
        transSerialNo: '000101010' + i,
        tel: '1390428800' + i,
        transTime: '2024-05-0' + (i + 1),
        transType: i % 2,
        transTypeDesc: transTypeDesc[i % 2],
        transClassify: i % 5,
        transClassifyDesc: transClassifyDesc[i % 5],
        payMoney: parseFloat((Math.random() * 99999).toFixed(2)),
        transCourse: transCourseDesc[i % 2],
        transCourseBalance: parseFloat((Math.random() * 30000).toFixed(2)),
        bizOrderNo: 'HJKDHKH5683465634852' + i
      };
      list.push(dic);
    }
    return list;
  }
}
