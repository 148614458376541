/**
 * Created by 程雨喵'mac on 2023/12/13.
 * Copyright © 2023年 云柜-金星晖. All rights reserved.
 * 名称：directive: v-text-unit="min", value: data-value="金额"
 */
const DIC = {
  min: '分钟',
  w: 'W',
  kw: '千瓦',
  num: '号',
  du: '度',
  p: '%',
  b: '币',
  wa: '瓦',
  ko: '口',
  tai: '台',
  dan: '单'
};
function inserted (el, binding, vnode) {
  // 1.查看功能是否开启
  if (!binding.value) return;
  // 2.文本
  let text = el.dataset.value;
  // 3.单位
  const unitKey = binding.value;
  if (text) {
    if (typeof text === 'string') {
      text = Number(text);
    }
    if (Number.isNaN(text)) {
      text = '-';
    } else {
      text = `${text}${DIC[unitKey]}`;
    }
  } else {
    text = '-';
  }
  el.innerText = text;
}
function update (el, binding, vnode) {
  inserted(el, binding, vnode);
}
export default {
  install (Vue) {
    Vue.directive('text-unit', {
      inserted,
      update
    });
  }
};
