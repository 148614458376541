import BaseModel from '@/libs/base/dataModel/BaseModel';
import GLOBAL_CONFIG from '@/app.config';

const MANUFACTURE_DIC = {
  10: '超翔',
  40: '友电',
  50: '尚亿'
};
const SIM_CARD_EE = GLOBAL_CONFIG.simCardEE;

export class DeviceListModel extends BaseModel {
  constructor () {
    super();
    this.id = ''; // 设备id
    this.activeSpName = ''; // 服务商名称
    this.name = ''; // 设备名称
    this.deviceCode = ''; // 设备编号
    this.deviceNumber = ''; // 设备编号
    this.devicePortCount = ''; // 设备端口数量
    this.isOnline = ''; // 在线状态 0：不在线，1：在线
    this.signal = ''; // 信号强度
    this.offlineCount = ''; // 当日离线次数
    this.deviceOfflineCount = ''; // 当日离线次数
    this.iccid = ''; // 物联网SIM卡号
    this.imei = ''; // 网络设备编号
    this.activeTime = ''; // 启用时间
    this.deviceType = ''; // 设备类型
    this.softVersion = ''; // 程序版本
    this.location = ''; // 经纬度
    this.propertyCompanyId = ''; // 物业公司ID
    this.propertyCompanyName = ''; // 物业名称
    this.communityId = ''; // 小区ID
    this.communityName = ''; // 小区名称
    this.siteId = ''; // 点位ID
    this.siteCode = ''; // 点位编码
    this.siteName = ''; // 点位名称
    this.province = ''; // 省
    this.provinceName = ''; // 省名称
    this.city = ''; // 市
    this.cityName = ''; // 市名称
    this.districtName = ''; // 区名称
    this.serviceCall = ''; // 运维服务电话
    this.manager = ''; // 运维人员姓名
    this.remark = ''; // 备注
    this.status = ''; // 数据状态（1：启用，0：禁用）
    this.devicePortCountReal = ''; // 设备端口真实数量
    this.deviceSource = ''; // 设备来源：0-内部 1-小橙
    this.deviceSourceDesc = ''; // 设备来源：0-内部 1-小橙
    this.deviceProviderName = ''; // 设备商名称（10:超翔 20：山野）
    this.deviceModel = ''; // 设备型号编码
    this.deviceModelName = ''; // 设备型号名称
    this.chargingPileType = ''; // 端口类别
    this.belongTypeDesc = ''; // 主从类型
    this.belongDeviceNumber = ''; // 所属主机
    this.operationType = ''; // 运营类型 0-自有、1-共建、2-外拓
    this.operationTypeDesc = ''; // 运营类型描述
    this.freeConfig = ''; // 6分钟免费配置:0->关闭、1->开启
    this.swipeCardCostNumber = ''; // 刷卡扣充电币数量
    this.fictitiousPower = ''; // 结算功率虚位
    this.manufacturers = ''; //  10-超翔 20-山野 30-小橙 40-友电 50-尚亿
    this.chargeCategory = 1; // 1：按功率计费，2：按电量计费
    this.deviceUid = ''; // 设备UID
    this.installTime = ''; // 安装时间
    this.sitePosition = ''; // 点位位置
    this.sitePositionDesc = ''; // 点位位置描述
    this.canopyStatus = ''; // 雨棚状态
    this.canopyStatusDesc = ''; // 雨棚状态描述

    // 前端专用 ==============>
    this.h5StatusSwitch = false;
    this.h5IsOnlineName = '';
    this.h5ManufactureDesc = '';
    this.h5InstallDate = '';
    this.h5InstallTime = '';
    // 运营商
    this.h5SimCardEE = '未知';
    // 是否已选中
    this._checked = false;
    this._expanded = true; // 默认展开用
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==============>
  }

  initData (resData) {
    super.initData(resData);
    this.freeConfig = this.freeConfig + '';
    this.h5ManufactureDesc = MANUFACTURE_DIC[this.manufacturers] || '未知';
    // this.deviceNumber = this.deviceNumber.length;
    this.h5StatusSwitch = !!this.status;
    this.h5IsOnlineName = this.isOnline === 1 ? '在线' : '离线';
    this.swipeCardCostNumber = this.swipeCardCostNumber - 0;
    // this.fictitiousPower = this.fictitiousPower - 0;
    this.belongTypeDesc = `${this.h5ManufactureDesc}${this.belongTypeDesc}`;
    this.fictitiousPower = -999;
    if (this.installTime) {
      const date = this.installTime.split(' ');
      this.h5InstallDate = date[0];
      this.h5InstallTime = date[1];
    }

    // 查看运营商
    if (this.iccid && this.iccid.length > 5) {
      const str = this.iccid[4] + this.iccid[5];
      if (str) {
        for (let i = 0; i < SIM_CARD_EE.length; i++) {
          const dic = SIM_CARD_EE[i];
          const item = dic.items.find(v => v === str);
          if (item) {
            this.h5SimCardEE = dic.title;
            break;
          }
        }
      }
    }

    this._checkId = this.id;
    return this;
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: '000101010' + i,
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        deviceCode: BaseModel.getFakeNumber(99999, 0),
        devicePortCount: i % 2 ? 10 : 0,
        isOnline: i % 2,
        signalStrength: BaseModel.getFakeNumber(31, 0),
        deviceOfflineCount: BaseModel.getFakeNumber(3, 0),
        iccid: BaseModel.getFakeNumber(9999999, 0),
        imei: BaseModel.getFakeNumber(9999999, 0),
        activeTime: '2024-01-01',
        deviceType: '充电桩',
        softVersion: BaseModel.getFakeNumber(10),
        provinceName: BaseModel.getFakeTitle(4) + '省',
        cityName: BaseModel.getFakeTitle(6) + '市',
        districtName: BaseModel.getFakeTitle(6) + '区',
        remark: i % 2 ? BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)) : '',
        serviceCall: '13333333333',
        manager: BaseModel.getFakeTitle(Math.ceil(Math.random() * 3))
      };
      list.push(dic);
    }
    return list;
  }
}
export class DeviceDetailModel extends DeviceListModel {
  constructor () {
    super();
    this.idCard = ''; // 证件号码
    this.idCardType = ''; // 证件类型
    this.branchBank = ''; // 开户行--支行
    this.bankNo = ''; // 银行账号
  }

  static createFakeData () {
    const dic = this.createFakeDataList(1)[0];
    return {
      ...dic,
      ...{
        idCard: '21315465732132100',
        idCardType: '身份证',
        branchBank: '招商银行',
        bankNo: '4003894502132465789'
      }
    };
  }

  static createAdditionModel () {
    return new this();
  }
}
