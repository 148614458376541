<!--
    Created by 程雨喵'mac on 2024/1/23.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：列表中使用的信号(最小宽度要30, 高度20)
-->
<style lang="less">
.__g-signal {
  //.display-flex();
  .__g-signal-line-box {
    width: 30px;
    height: 20px;
    display: flex;
    align-items: flex-end;
    .__g-signal-line-item {
      width: 3px;
      margin-right: 1px;
      //border-radius: 2px 2px 0 0;
      background-color: @buttonColorDisabled;
      &.is-highlight {
        background-color: @finishColor;
      }
    }
  }
  .status-box {
    &-1 {
      color: @finishColor;
      font-weight: 600;
    }
    color: @textColorLight;
    font-size: 11px;
    line-height: 11px;
    text-align: center;
    font-weight: 400;
  }
}
.__g-signal-hover-content {
  padding: 30px 15px 10px;
  width: 200px;
  font-size: 12px;
  .__g-signal-range {
    display: flex;
    justify-content: space-between;
    color: @textColorLight;
  }
  .__g-signal-bg {
    position: relative;
    height: 10px;
    background: linear-gradient(90deg, rgba(27,197,189,0.15) 40%, rgba(27,197,189,1) 100%);
    .__g-signal-current {
      position: absolute;
      padding: 0 5px;
      height: 20px;
      top: -30px;
      left: 0;
      font-size: 12px;
      background-color: rgba(27,197,189,0.1);
      border: 1px solid rgba(27,197,189,1);
      color: rgba(27,197,189,1);
      border-radius: 3px;
      transform: translateX(-50%);
      font-weight: 600;
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        width: 2px;
        bottom: -11px;
        height: 10px;
        transform: translateX(-50%);
        background-color: rgba(27,197,189,1);
      }
    }
  }
}
.device-list-page-current-iccid {
  display: flex;
  padding-top: 5px;
  border-top: 1px solid #ebebeb;
  .is-high-light {
    color: white;
    background-color: #FE5571;
  }
}
.device-list-page-current-iccid-dialog {
  position: relative;
  padding: 0 15px;
  .sim-card-box-header {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #ebebeb;
    border-bottom: 0;
    //background-color: #F7F7FB;
    background-color: #F7F7FB;
    .header-item {
      padding: 0 2px;
      color: white;
      background-color: rgba(84,110,253,1);
      //background-color: rgba(84,110,253,0.1);
      //font-weight: 400;
      &:nth-of-type(2n) {
        //color: white;
        //background-color: white;
        background-color: rgba(27,197,189,1);
        //background-color: rgba(27,197,189,0.1);
      }
    }
  }
  .sim-card-box {
    position: relative;
    z-index: 1;
    display: flex;
    border: 1px solid #ebebeb;
    border-right: 0;
    line-height: 16px;
    .sim-slice-item {
      border-right: 1px solid #ebebeb;
      font-size: 12px;
      text-align: center;
      &.has-background-color {
        background-color: #F7F7FB;
        font-size: 11px;
      }
      .k, .t, .v, .d {
        padding: 0 5px;
      }
      .t {
        border-top: 1px solid #ebebeb;
      }
      .v {
        border: 1px solid #ebebeb;
        border-left: 0;
        border-right: 0;
      }
    }
  }
  .sim-card-info {
    position: relative;
    z-index: 1;
    border: 1px solid #ebebeb;
    border-top: 0;
    text-align: center;
    font-size: 12px;
    background-color: #F7F7FB;
  }
  .tip-info {
    margin: 0 auto;
    padding: 5px 0;
    width: 90px;
    background-color: white;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    color: #a0a0a0;
  }
}
</style>

<template>
  <div class="__g-signal">
    <Poptip
      v-if="showSignal"
      trigger="hover"
      transfer
    >
      <a class="__g-signal-line-box">
        <div
          v-for="(item, i) in lineNum"
          :key="i"
          class="__g-signal-line-item"
          :style="getLineHeight(i)"
          :class="item <= highLightIndex ? 'is-highlight' : ''"
        />
      </a>
      <div slot="title">
        信号强度
        <!--{{ currentLeft }}-->
      </div>
      <div slot="content">
        <div class="__g-signal-hover-content">
          <div class="__g-signal-bg">
            <div
              :style="currentLeft"
              class="__g-signal-current"
            >
              {{ value }}
            </div>
          </div>
          <div class="__g-signal-range">
            <div>{{ range[0] }}</div>
            <div>{{ range[1] }}</div>
          </div>
        </div>
      </div>
    </Poptip>
    <div
      class="status-box"
      :class="'status-box-' + statusValue"
    >
      <Poptip
        trigger="hover"
        transfer
      >
        <div class="display-flex display-flex-v-center">
          {{ simEE }}{{ statusDesc }}
          <Icon type="md-help-circle" />
        </div>
        <div
          v-if="simCardInfos.length"
          slot="content"
          class="device-list-page-current-iccid-dialog"
        >
          <div class="mask-div" />
          <div
            class="tip-info"
          >
            数据仅供参考
          </div>
          <div
            class="sim-card-box-header"
          >
            <div style="margin-right: 5px">
              当前SIM卡
            </div>
            <div
              v-for="(str, s) in simCardInfos"
              :key="'str-' + s"
              class="header-item"
            >
              {{ str.value }}
            </div>
            <div style="margin-left: 5px">
              拆解
            </div>
          </div>
          <div class="sim-card-box">
            <div
              class="sim-slice-item has-background-color"
            >
              <div class="k">
                规则
              </div>
              <div class="t">
                规则含义
              </div>
              <div class="v">
                值
              </div>
              <div class="d">
                值含义
              </div>
            </div>
            <div
              v-for="(item, i) in simCardInfos"
              :key="'sim-' + i"
              class="sim-slice-item"
            >
              <div class="k">
                {{ item.key }}
              </div>
              <div class="t">
                {{ item.title }}
              </div>
              <div class="v">
                {{ item.value }}
              </div>
              <div class="d">
                {{ item.desc }}
              </div>
            </div>
          </div>
          <div
            class="sim-card-info"
          >
            集成电路卡识别码(ICCID)编码规则: IIN MCC EE MN SS YY G XXXXXXX
          </div>
          <div
            class="tip-info"
          >
            数据仅供参考
          </div>
        </div>
        <div
          v-else
          slot="content"
        >
          无SIM卡信息
        </div>
      </Poptip>
    </div>
  </div>
</template>

<script>
import GLOBAL_CONFIG from '@/app.config';
export default {
  name: 'YgTableSignal',
  components: {},
  props: {
    range: {
      type: Array,
      default: () => {
        return [0, 31];
      }
    },
    // 当前的值
    value: {
      type: [Number, String],
      default: 0
    },
    showSignal: {
      type: Boolean,
      default: true
    },
    // 当前状态
    statusValue: {
      type: [Number, String],
      default: 0
    },
    statusDesc: {
      type: String,
      default: ''
    },
    // 运营商
    simEE: {
      type: String,
      default: ''
    },
    simIccid: {
      type: String,
      default: '-'
    }
  },
  data () {
    return {
      // 竖线数量
      lineNum: 8,
      // 需要高亮的下标(在这之前的都会高亮)
      highLightIndex: 0,
      currentValue: 0,
      currentLeft: '',
      simCardFactory: {
        0: '雅斯拓',
        1: 'GEMPLUS',
        2: '武汉天喻',
        3: '江西捷德',
        4: '珠海东信和平',
        5: '大唐微电子通',
        6: '航天九州通',
        7: '北京握奇',
        8: '恒宝',
        9: '北京华虹',
        A: '上海柯斯',
        B: '欧贝特',
        C: '楚天龙',
        F: 'SoftSIM卡商'
      },
      simCardInfos: [],
      simCardOpen: false
    };
  },
  watch: {
    value (o, n) {
      if (o !== n) {
        this.setCurrentValue();
      }
    }
  },
  created () {
    this.setCurrentValue();
    this.setCurrentSimCard();
  },
  methods: {
    setCurrentValue () {
      let v = this.value;
      if (typeof this.value === 'string') {
        v = Number(this.value);
      }
      // v = 100;
      this.currentValue = v;
      const s = this.range[0];
      const e = this.range[1];
      // 占比
      const n = this.currentValue / (e - s);
      const lineNum = this.lineNum;
      let h = Math.ceil(lineNum * n);
      if (h < 0) h = 0;
      if (h > lineNum) h = lineNum;
      this.highLightIndex = h;
      let percent = n * 100;
      if (percent > 100) percent = 100;
      this.currentLeft = `left:${percent}%;`;
    },
    setCurrentSimCard () {
      this.simCardInfos = [];
      if (this.simIccid && this.simIccid.length > 0) {
        const simCardInfos = [];
        /*
          sim卡规则
          规则0：0~1 IIN 国际编号
          规则1：2~3 MCC 国家编号
          规则2：4~5 EE 分配给各运营商的号段
          规则3：6~7 MN 业务接入号(号段及之后的数字）
          规则4：8~9 SS 省份
          规则5：10~11 YY 年份
          规则6：12 G 卡商编号
         */
        // 规则0判断
        const ruleStr0 = this.simIccid.slice(0, 2);
        simCardInfos.push({
          title: '国际编号',
          value: ruleStr0,
          desc: ruleStr0 === '89' ? '电信用途' : '未知',
          key: 'IIN'
        });

        // 规则1判断
        const ruleStr1 = this.simIccid.slice(2, 4);
        simCardInfos.push({
          title: '国家编号',
          value: ruleStr1,
          desc: ruleStr1 === '86' ? '中国' : '未知',
          key: 'MCC'
        });
        // 规则2判断
        const dataEE = GLOBAL_CONFIG.simCardEE;
        const ruleStr2 = this.simIccid.slice(4, 6);
        let str2Value = '未知';
        for (let i = 0; i < dataEE.length; i++) {
          const dic = dataEE[i];
          const item = dic.items.find(v => v === ruleStr2);
          if (item) {
            str2Value = dic.title;
            break;
          }
        }
        simCardInfos.push({
          title: '运营商',
          value: ruleStr2,
          desc: str2Value,
          key: 'EE'
        });
        // 规则3
        simCardInfos.push({
          title: '业务号',
          value: this.simIccid.slice(6, 8),
          desc: '-',
          key: 'MN'
        });
        // 规则4
        const dataSS = GLOBAL_CONFIG.simCardSS;
        const ruleStr4 = this.simIccid.slice(8, 10);
        const str4Value = dataSS[ruleStr4] || '未知';
        simCardInfos.push({
          title: '省份',
          value: ruleStr4,
          desc: str4Value,
          key: 'SS'
        });
        // 规则5
        const ruleStr5 = this.simIccid.slice(10, 12);
        simCardInfos.push({
          title: '生产年份/批次',
          value: ruleStr5,
          desc: ruleStr5 ? ('20' + ruleStr5 + '年产或' + ruleStr5 + '批次') : '-',
          key: 'YY'
        });
        // 规则6
        const ruleStr6 = this.simIccid[12];
        const str6Value = this.simCardFactory[ruleStr6] || '未知';
        simCardInfos.push({
          title: '厂商',
          value: ruleStr6,
          desc: str6Value,
          key: 'G'
        });
        // 其他
        simCardInfos.push({
          title: '序列号',
          value: this.simIccid.slice(13, this.simIccid.length + 1),
          desc: '-',
          key: 'XXXXXXX'
        });
        this.simCardInfos = simCardInfos;
      }
    },
    getLineHeight (index) {
      return `height:${20 - (this.lineNum - index) * 1.5}px`;
    }
  }
};
</script>
