/**
 * @date:2023/11/01
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */
import { formatDate } from '@/libs/utils';

// 页面顶部搜索使用的每个item
class BaseSearchModel extends Object {
  constructor () {
    super();
    this._isSearchModel = true;
    this.label = null; // 左侧标题
    this.labelIsSelect = false; // 左侧标题的为下拉
    this.labelSelectOption = null; // 左侧标题的下拉项
    this.labelSelectValue = null; // 下拉项的值
    this._labelSelectTitle = null; // 下拉项的标题
    this._labelSelectWidthStyle = '';
    this.key = null; // 字段的key
    // 是否展示在更多里面
    this.showInMore = false;
    // 标签名称 Input, Cacader, Select, DatePicker..
    this.tagName = '';
    this._value = ''; // 值
    this.clearable = true; // 是否展示清除按钮，默认展示
    this.data = []; // 级联动和选择框时使用的
    this.default = null; // 重置后还会选择此值
    // 条件互斥时使用此字段，比如说输入用户ID时手机号和名称不可输入，那么此时里面的值应该是tel,name
    this.exclusions = [];
    this.disabled = false;
    this.multiple = false; // 是否多选
    // 输入的值为数字时，使用
    this.isNumberRange = false;
    // isNumberRange === true 时，可以指定值的范围(即可取的最小值与最大值，超过这个范围会报错！！)，默认：[0, 8760]
    this.numberRange = null;
    this.unit = ''; // 单位
    /*
      值回显使用
      注：不要在类外面赋值，要么触发_value的set方法，要么使用对外暴露的setValueDesc方法
     */
    this.valueDesc = '';
    this.valueDataOption = [];
  }

  setValueDesc (v) {
    this.valueDesc = v;
  }

  set labelSelectTitle (value) {
    this._labelSelectTitle = value;
  }

  get labelSelectTitle () {
    this._labelSelectTitle = this.labelSelectOption.find(v => v.value === this.labelSelectValue).label;
    return this._labelSelectTitle;
  }

  set value (v) {
    this._value = v;
    if (this.tagName === 'Select') {
      if (Array.isArray(v)) {
        const selectedLabel = [];
        v.forEach(res => {
          const item = this.data.find(val => val.value === res);
          selectedLabel.push(item.label);
        });
        this.valueDesc = selectedLabel.join('-');
      } else {
        if (v || v === 0) {
          this.valueDesc = this.data.find(val => val.value === v).label;
        } else {
          this.valueDesc = '';
        }
      }
    } else if (this.tagName === 'YgGlobalSelect' || this.tagName === 'YgRegionCascader' || this.tagName === 'YgSiteCascader') {
      if (Array.isArray(v)) {
        const selectedLabel = [];
        v.forEach(res => {
          const item = this.valueDataOption.find(val => val.value === res);
          selectedLabel.push(item.label);
        });
        this.valueDesc = selectedLabel.join('-');
      } else {
        if (v) {
          this.valueDesc = this.valueDataOption.find(val => val.value === v).label;
        } else {
          this.valueDesc = '';
        }
      }
    } else if (this.tagName === 'DatePickerRange') {
      const dateRange = [];
      v.forEach(res => {
        if (res) {
          dateRange.push(formatDate(new Date(res)));
        }
      });
      this.valueDesc = dateRange.join('-');
    } else {
      if (Array.isArray(v)) {
        v.forEach(res => {
          this.valueDesc = +res;
        });
      } else {
        this.valueDesc = v;
      }
    }
  }

  get value () {
    return this._value;
  }

  setClearable (value) {
    this.clearable = value;
    return this;
  }

  /**
   * 设置左侧label为下拉
   * @param options [{value: '此条件对应的key', label: '下拉展示内容' }]
   * 举例：[{value: 'userTel', label: '用户手机号'}, {value: 'userId', label: '用户id'}]，这样写之后请求参数直接取value就行了，不用再写判断条件
   * @returns {BaseSearchModel}
   */
  setLabelSelect (options) {
    this.labelIsSelect = true;
    this.labelSelectOption = options;
    // 默认取第一个值
    this.labelSelectValue = options[0].value;
    this.labelSelectTitle = options[0].label;
    this._labelSelectWidthStyle = `max-width:${options[0].label.length * 12 + 40}px;`;
    return this;
  }

  setData (value) {
    this.data = value;
    return this;
  }

  setDefault (value) {
    this.default = value;
    this.value = value;
    return this;
  }

  setDisabled (value) {
    this.disabled = value;
    return this;
  }

  setMultiple (value) {
    this.multiple = value;
    return this;
  }

  setExclusions (value) {
    this.exclusions = value;
    return this;
  }

  setIsNumberRange (range = [0, 8760]) {
    this.isNumberRange = true;
    this.numberRange = range;
    return this;
  }

  setUnit (unit) {
    this.unit = unit;
    return this;
  }

  setShowInMore (value) {
    this.showInMore = value;
    return this;
  }

  /**
   * 特殊属性：DatePickerRange，可快速指定左侧的选择栏(当然也可以页面中指定data来自行实现)
   * value: ['今天', '昨天', '近7天', '近30天', '近90天', '近180天', '近365天']
   */
  setDatePickerElevator (list) {
    if (Array.isArray(list)) {
      const arr = [];
      const onDay = 86400000;
      if (list.length === 0) {
        // 啥都没放就是要全部！
        list = ['今天', '昨天', '近7天', '近30天', '近90天', '近180天', '近365天'];
      }
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        const value = () => {
          const end = new Date();
          const start = new Date();
          if (item === '昨天') {
            start.setTime(start.getTime() - onDay);
            end.setTime(end.getTime() - onDay);
          } else if (item === '近7天') {
            start.setTime(start.getTime() - onDay * 7);
          } else if (item === '近30天') {
            start.setTime(start.getTime() - onDay * 30);
          } else if (item === '近90天') {
            start.setTime(start.getTime() - onDay * 90);
          } else if (item === '近180天') {
            start.setTime(start.getTime() - onDay * 180);
          } else if (item === '近365天') {
            start.setTime(start.getTime() - onDay * 365);
          }
          return [start, end];
        };
        arr.push({
          text: item,
          value
        });
      }
      this.data = {
        shortcuts: arr
      };
    }
    return this;
  }

  // 深拷贝
  static deepCopy (model) {
    // 1.先通过序列化与反序列化，开辟新的内存空间
    let datePickerRange = null;
    if (model.tagName === 'DatePickerRange') {
      datePickerRange = model.data;
    }
    const temp = JSON.parse(JSON.stringify(model));
    const resModel = new BaseSearchModel();
    for (const key in temp) {
      resModel[key] = temp[key];
    }
    if (datePickerRange) {
      resModel.data = datePickerRange;
    }
    return resModel;
  }

  // 类方法-遍历构造器
  static initData (label, key, tagName) {
    if (label && key && tagName) {
      const model = new BaseSearchModel();
      model.label = label;
      model.key = key;
      model.tagName = tagName;
      if (tagName === 'DatePickerRange' || tagName === 'Cascader' || tagName === 'YgCascaderBusiness' || tagName === 'YgRegionCascader' || tagName === 'YgSiteCascader' || tagName === 'YgRangeInput') {
        model.value = [];
      }
      if (tagName === 'DatePickerRange') {
        model.data = {};
      }
      return model;
    }
    throw (new Error('缺少必传参数'));
  }
}

export default BaseSearchModel;
