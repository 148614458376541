<!--
    Created by 王丽莎 on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：充电管理-充电业务订单列表
-->
<style lang="less">
#charging-business-order {
  .ivu-table-cell {
    max-height: 88px;
  }
  .order-li-status {
    color: @textColorLight;
    &-1 {
      //margin-left: 5px;
      //background-color:  @themeColor;
      //color: white;
      //text-align: center;
      //border-radius: @borderRadiusMin;
      color: @adornColor;
      font-weight: 600;
      //font-size: 10px;
    }
  }
}
</style>

<template>
  <div
    v-if="pageReady && !isDetailPage"
    id="charging-business-order"
  >
    <!-- 查询条件 -->
    <div
      v-permission="'system:chargeOrder:query'"
    >
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:chargeOrder:query'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        :row-class-name="mixinTableRowClassName"
        @on-row-expand="mixinTableOnRowExpand"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
      />
    </div>
    <!--弹窗：查看各类型订单-->
    <yg-order-list
      v-if="!!dialog.orderListModel"
      :parameter-type="dialog.parameterType"
      :type="dialog.orderListType"
      :model="dialog.orderListModel"
      @on-close="dialog.orderListModel = null"
      @on-sure="dialog.orderListModel = null"
    />
    <!--订单详情-->
    <v-detail
      v-if="dialog.detailModel"
      :model="dialog.detailModel"
      @on-close="dialog.detailModel = null"
    />
    <!--操作退款-->
    <v-submit
      v-if="dialog.refundModel"
      :model="dialog.refundModel"
      @on-close="dialog.refundModel = null"
      @on-sure="_clickSubmitRefund"
    />
    <!--图表-->
    <v-data-hook
      v-if="dialog.chartsDataModel"
      :model="dialog.chartsDataModel"
      @on-close="dialog.chartsDataModel = null"
    />
    <!--计费类型-->
    <v-rules
      v-if="dialog.deviceRulesModel"
      :model="dialog.deviceRulesModel"
      @on-close="dialog.deviceRulesModel = null"
    />
  </div>
  <div v-else-if="pageReady && isDetailPage">
    <v-detail
      v-if="dialog.detailModel"
      :model="dialog.detailModel"
      @on-close="dialog.detailModel = null;isDetailPage=false"
    />
  </div>
</template>

<script>
import OrderApiSet from '@/request/api/OrderApiSet';
import {
  ChargingBusinessOrderListModel,
  ChargingBusinessOrderDetailModel
} from './model/ChargingBusinessOrderDataModel';
import pageTableExpandMixin from '@/libs/mixins/pageTableExpand';
import ColumnMixin from '@/pages/060__rechargeManager/chargingBusinessOrder/mixin/tableColumn';
import commonPageDrawerMixin from '@/components/common/order-list/commonPageDrawerMixin';
import vDetail from './view/detail.vue';
import vSubmit from './view/submit.vue';
import vRules from './view/detail-subView/deviceRules.vue';
import vDataHook from './view/dataHook.vue';
import { getLastDate, getNowDate } from '@/libs/utils';

export default {
  name: 'ChargingBusinessOrder',
  components: { vDetail, vSubmit, vRules, vDataHook },
  mixins: [ColumnMixin, pageTableExpandMixin, commonPageDrawerMixin],
  props: {},
  data () {
    return {
      isDetailPage: false,
      pageReady: false,
      searchOption: {
        deviceNoLabel: BaseSearchModel.initData('设备编码', 'deviceNoLabel', 'Input').setLabelSelect([
          { value: '1', label: '设备编码' },
          { value: '2', label: '设备名称' },
          { value: '3', label: '设备型号名称' },
          { value: '4', label: 'IMEI号' }
        ]),
        selectOrderLabel: BaseSearchModel.initData('充电订单编码', 'selectOrderLabel', 'Input').setLabelSelect([
          { value: 'orderNo', label: '充电订单编码' },
          { value: 'payTradeNo', label: '支付订单编码' },
          // { value: 'gwTradeNo', label: '交易渠道订单号' },
          { value: 'tel', label: '用户手机号' },
          { value: 'chargeNumber', label: '充电卡号' }
        ]),
        communityName: BaseSearchModel.initData('小区名称', 'communityName', 'Input'),
        regionCompany: BaseSearchModel.initData('物业区域公司', 'regionCompany', 'YgGlobalSelect').setData('regionalCompanyList').setExclusions(['officeCode', 'region']).setShowInMore(true),
        officeCode: BaseSearchModel.initData('吾行办事处', 'officeCode', 'YgGlobalSelect').setData('officeList').setExclusions(['regionCompany', 'region']).setShowInMore(true),
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2).setExclusions(['regionCompany', 'officeCode']),
        chargeStatus: BaseSearchModel.initData('充电状态', 'chargeStatus', 'Select').setData([
          { label: '待充电', value: '0' },
          { label: '充电中', value: '1' },
          { label: '已结束', value: '2' },
          { label: '已取消', value: '3' },
          { label: '充电失败', value: '4' }
        ]).setExclusions(['duration']),
        startupType: BaseSearchModel.initData('启动方式', 'startupType', 'Select').setData([
          { label: '小程序', value: '1' },
          { label: '刷卡', value: '2' }
        ]).setShowInMore(true),
        deviceProviderId: BaseSearchModel.initData('设备商名称', 'deviceProviderId', 'YgGlobalSelect').setData('deviceProvider').setShowInMore(true),
        endReason: BaseSearchModel.initData('结束原因', 'endReason', 'YgGlobalSelect').setData('endReasonList').setShowInMore(true),
        displayCode: BaseSearchModel.initData('端口号', 'displayCode', 'Select').setData([
          { label: '1号口', value: '1' },
          { label: '2号口', value: '2' },
          { label: '3号口', value: '3' },
          { label: '4号口', value: '4' },
          { label: '5号口', value: '5' },
          { label: '6号口', value: '6' },
          { label: '7号口', value: '7' },
          { label: '8号口', value: '8' },
          { label: '9号口', value: '9' },
          { label: '10号口', value: '10' },
          { label: '11号口', value: '11' },
          { label: '12号口', value: '12' }
        ]).setShowInMore(true),
        category: BaseSearchModel.initData('计费类型', 'category', 'Select').setData([
          { label: '按功率计费', value: '1' },
          { label: '按电量计费', value: '2' },
          { label: '按时间计费', value: '3' }
        ]).setShowInMore(true),
        duration: BaseSearchModel.initData('实际充电时长', 'duration', 'YgRangeInput').setExclusions(['chargeStatus']).setDefault(['', '']).setIsNumberRange().setUnit('分钟').setShowInMore(true),
        selectTimeLabel: BaseSearchModel.initData('时间', 'selectTimeLabel', 'DatePickerRange').setDatePickerElevator([]).setLabelSelect([
          { value: 'startTimeStart-startTimeEnd', label: '开始时间' },
          { value: 'realEndTimeStart-realEndTimeEnd', label: '结束时间' }
        ]).setDefault([getLastDate(30), getNowDate()]).setClearable(false).setShowInMore(true)
      },
      settingExportIdList: [],
      settingExportCondition: {},
      settingItems: [
        BaseSettingModel.initData('导出列表', 'export', 'md-cloud-download').setExport().setExportReportType(10).setPermission('system:chargeOrder:export')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        orderListModel: null,
        orderListType: null,
        parameterType: null,
        detailModel: null,
        refundModel: null,
        deviceRulesModel: null, // 计费类型
        chartsDataModel: null // 图表
      }
    };
  },
  created () {
    this.pageReady = true;
    if (this.$route.query && this.$route.query.orderNo) {
      this.isDetailPage = true;
      this.requestDetail(this.$route.query.orderNo);
      this.$route.query.orderNo = '';
      return;
    }
    // 配置展开项内容
    this.mixinTableExpandSetColumnOperation(2, [
      {
        title: '设备信息',
        col: '50',
        items: [
          { col: '25', label: '设备编码:', key: 'deviceNumber', canCopy: false, canTooltip: true, canHidePhone: false, canClick: true },
          { col: '25', label: 'IMEI号:', key: 'imei', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '设备名称:', key: 'deviceName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '设备型号名称:', key: 'deviceModelName', canCopy: false, canTooltip: true, canHidePhone: false }
        ]
      },
      {
        title: '点位信息',
        col: '50',
        items: [
          { col: '25', label: '省市区:', key: 'addressName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '小区名称:', key: 'blockName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '点位名称:', key: 'siteName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '', key: '', canCopy: false, canTooltip: true, canHidePhone: false }
        ]
      }
    ], (row, index, { key }) => {
      if (key === 'deviceNumber') {
        this.dialog.parameterType = 3;
        this.dialog.orderListType = 5;
        this.dialog.orderListModel = row;
      }
    });
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'download':
        this.$Message.info('点击导出');
        break;
      }
    },
    // 确认退款
    _clickSubmitRefund (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '是否确认提交，提交后请等待处理结果',
        onOk: () => {
          setTimeout(() => {
            this.requestSubmitRefund(params);
          }, 300);
        }
      });
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    // 请求列表
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const s = this.searchOption;
      const api = OrderApiSet.getOrderList;
      api.params = {
        queryType: s.deviceNoLabel.value ? s.deviceNoLabel.labelSelectValue : '',
        queryName: s.deviceNoLabel.value,
        chargeStatus: s.chargeStatus.value,
        startupType: s.startupType.value,
        provinceName: s.region.value[0] || '',
        cityName: s.region.value[1] || '',
        district: s.region.value[2] || '',
        communityName: s.communityName.value,
        officeCode: s.officeCode.value,
        orgCode: s.regionCompany.value,
        deviceProviderId: s.deviceProviderId.value,
        endReason: s.endReason.value,
        category: s.category.value,
        displayCode: s.displayCode.value,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      // 实际充电时长(分钟)
      const duration = s.duration.value;
      api.params.minRealChargeTime = duration[0] || '';
      api.params.maxRealChargeTime = duration[1] || '';
      // 筛选中可下拉的label
      api.params[s.selectOrderLabel.labelSelectValue] = s.selectOrderLabel.value;
      const timeKey = s.selectTimeLabel.labelSelectValue.split('-');
      api.params[timeKey[0]] = s.selectTimeLabel.value[0];
      api.params[timeKey[1]] = s.selectTimeLabel.value[1];
      this.settingExportCondition = api.params;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        const list = ChargingBusinessOrderListModel.initListWithDataList(resData.rows);
        this.tableData = this.mixinTableExpandDataSetExpand(list);
        ChargingBusinessOrderListModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      });
    },
    // 请求详情
    requestDetail (orderNo, key) {
      const api = OrderApiSet.getOrderDetail;
      api.params = { orderNo };
      this.$http(api).then(res => {
        const resData = res.data;
        if (!resData || !resData.userInfo) {
          this.isDetailPage = false;
          return this.$Message.warning('订单无详情');
        }
        this.dialog[key] = ChargingBusinessOrderDetailModel.initModelWithData(resData);
      });
      // const resData = ChargingBusinessOrderDetailModel.createFakeData();
      // this.dialog.detailModel = ChargingBusinessOrderDetailModel.initModelWithData(resData);
    },
    // 终止订单
    requestEndOrder (model) {
      const api = OrderApiSet.endOrder;
      api.params = {
        orderNo: model.orderNo
      };
      api.autoConfig.autoToast = false;
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.$ygLoading.close();
        this.requestData();
      }).catch(e => {
        this.$ygLoading.close();
        this.$Modal.warning({
          title: '操作提示',
          content: e.msg,
          okText: '我知道了'
        });
      });
    },
    // 请求退款
    requestSubmitRefund (params) {
      const api = OrderApiSet.manualRefund;
      api.params = params;
      this.$http(api).then(res => {
        this.$Modal.confirm({
          title: '操作提示',
          content: '退款成功！',
          okText: '查看结果',
          cancelText: '关闭提示',
          onOk: () => {
            // 进入充电退款订单页面
            this.dialog.parameterType = 2;
            this.dialog.orderListType = 4;
            this.dialog.orderListModel = this.dialog.refundModel;
            this.dialog.refundModel = null;
            this.requestData();
          },
          onCancel: () => {
            this.dialog.refundModel = null;
            this.requestData();
          }
        });
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
