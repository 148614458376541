/**
 * Created by 程雨喵'mac on 2024/2/1.
 * Copyright © 2024年 云柜-金星晖. All rights reserved.
 */
import { YunGuiHttpClient } from '@/request/axios/HttpClient';
import CommonApiSet from '@/request/api/CommonApiSet';
export default {
  state: {
    payTypeChannelList: [],
    deviceTypeList: [],
    chargingPileTypeList: [],
    devicePowerTypeList: [],
    principalTypeList: [],
    chargeQrcodeDomainList: [],
    frontQrcodeEnumList: [],
    outsideChannelList: [],
    outsideOrderDataTypeList: [],
    outsideDeviceDataTypeList: [],
    outsideCustomerDataTypeList: [],
    orderDataSourceTypeList: [],
    deviceDataSourceTypeList: [],
    deviceProviderList: [],
    officeList: [],
    regionalCompanyList: [],
    endReasonList: [],
    // 厂商下拉
    factoryTypeList: [],
    // 设备商+厂商+设备类型 级联动(三级全量数据)
    factoryCascaderList: []
  },
  getters: {},
  mutations: {
    setPayTypeChannelList (state, value) {
      state.payTypeChannelList = value;
    },
    setDeviceTypeList (state, value) {
      state.deviceTypeList = value;
    },
    setChargingPileTypeList (state, value) {
      state.chargingPileTypeList = value;
    },
    setDevicePowerTypeList (state, value) {
      state.devicePowerTypeList = value;
    },
    setPrincipalTypeList (state, value) {
      state.principalTypeList = value;
    },
    setChargeQrcodeDomainList (state, value) {
      state.chargeQrcodeDomainList = value;
    },
    setFrontQrcodeEnumList (state, value) {
      state.frontQrcodeEnumList = value;
    },
    setOutsideChannelList (state, value) {
      state.outsideChannelList = value;
    },
    setOutsideOrderDataTypeList (state, value) {
      state.outsideOrderDataTypeList = value;
    },
    setOutsideDeviceDataTypeList (state, value) {
      state.outsideDeviceDataTypeList = value;
    },
    setOutsideCustomerDataTypeList (state, value) {
      state.outsideCustomerDataTypeList = value;
    },
    setOrderDataSourceTypeList (state, value) {
      state.orderDataSourceTypeList = value;
    },
    setDeviceDataSourceTypeList (state, value) {
      state.deviceDataSourceTypeList = value;
    },
    setDeviceProviderList (state, value) {
      state.deviceProviderList = value;
    },
    setOfficeList (state, value) {
      state.officeList = value;
    },
    setRegionalCompanyList (state, value) {
      state.regionalCompanyList = value;
    },
    setEndReasonList (state, value) {
      state.endReasonList = value;
    },
    setFactoryTypeList (state, value) {
      state.factoryTypeList = value;
    },
    setFactoryCascaderList (state, value) {
      state.factoryCascaderList = value;
    }
  },
  actions: {
    // 获取支付渠道下拉
    getPayTypeChannelList ({ state, commit }) {
      const api = CommonApiSet.getPayTypeChannel;
      return new Promise((resolve, reject) => {
        if (state.payTypeChannelList.length) {
          resolve(state.payTypeChannelList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setPayTypeChannelList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取设备类型下拉
    getDeviceTypeList ({ state, commit }) {
      const api = CommonApiSet.getDeviceType;
      return new Promise((resolve, reject) => {
        if (state.deviceTypeList.length) {
          resolve(state.deviceTypeList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setDeviceTypeList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取端口类别下拉
    getChargingPileTypeList ({ state, commit }) {
      const api = CommonApiSet.getChargingPileType;
      return new Promise((resolve, reject) => {
        if (state.chargingPileTypeList.length) {
          resolve(state.chargingPileTypeList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setChargingPileTypeList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取设备功率下拉
    getDevicePowerTypeList ({ state, commit }) {
      const api = CommonApiSet.getDevicePowerType;
      return new Promise((resolve, reject) => {
        if (state.devicePowerTypeList.length) {
          resolve(state.devicePowerTypeList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setDevicePowerTypeList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取主体类型下拉
    getPrincipalTypeList ({ state, commit }) {
      const api = CommonApiSet.getPrincipalType;
      return new Promise((resolve, reject) => {
        if (state.principalTypeList.length) {
          resolve(state.principalTypeList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setPrincipalTypeList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取充电二维码域名下拉
    getChargeQrcodeDomainList ({ state, commit }) {
      const api = CommonApiSet.getChargeQrcodeDomain;
      return new Promise((resolve, reject) => {
        if (state.chargeQrcodeDomainList.length) {
          resolve(state.chargeQrcodeDomainList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setChargeQrcodeDomainList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取前端二维码渠道枚举下拉
    getFrontQrcodeEnumList ({ state, commit }) {
      const api = CommonApiSet.getFrontQrcodeEnum;
      return new Promise((resolve, reject) => {
        if (state.frontQrcodeEnumList.length) {
          resolve(state.frontQrcodeEnumList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setFrontQrcodeEnumList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取外部订单数据下拉
    getOutsideOrderDataTypeList ({ state, commit }) {
      const api = CommonApiSet.getOutsideOrderDataType;
      return new Promise((resolve, reject) => {
        if (state.outsideOrderDataTypeList.length) {
          resolve(state.outsideOrderDataTypeList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setOutsideOrderDataTypeList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取外部设备数据下拉
    getOutsideDeviceDataTypeList ({ state, commit }) {
      const api = CommonApiSet.getOutsideDeviceDataType;
      return new Promise((resolve, reject) => {
        if (state.outsideDeviceDataTypeList.length) {
          resolve(state.outsideDeviceDataTypeList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setOutsideDeviceDataTypeList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取外部用户数据下拉
    getOutsideCustomerDataTypeList ({ state, commit }) {
      const api = CommonApiSet.getOutsideCustomerDataType;
      return new Promise((resolve, reject) => {
        if (state.outsideCustomerDataTypeList.length) {
          resolve(state.outsideCustomerDataTypeList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setOutsideCustomerDataTypeList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取订单数据来源下拉
    getOrderDataSourceTypeList ({ state, commit }) {
      const api = CommonApiSet.getOrderDataSourceType;
      return new Promise((resolve, reject) => {
        if (state.orderDataSourceTypeList.length) {
          resolve(state.orderDataSourceTypeList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setOrderDataSourceTypeList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取设备数据来源下拉
    getDeviceDataSourceTypeList ({ state, commit }) {
      const api = CommonApiSet.getDeviceDataSourceType;
      return new Promise((resolve, reject) => {
        if (state.deviceDataSourceTypeList.length) {
          resolve(state.deviceDataSourceTypeList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setDeviceDataSourceTypeList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取设备商下拉
    getDeviceProviderList ({ state, commit }) {
      const api = CommonApiSet.dpList;
      return new Promise((resolve, reject) => {
        if (state.deviceProviderList.length) {
          resolve(state.deviceProviderList);
        } else {
          YunGuiHttpClient(api).then(res => {
            const list = [];
            res.data.rows.forEach(result => {
              const item = result;
              item.label = result.deviceProviderName;
              item.value = result.id;
              list.push(item);
            });
            commit('setDeviceProviderList', list);
            resolve(list);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取办事处下拉
    getOfficeList ({ state, commit }) {
      const api = CommonApiSet.officeList;
      return new Promise((resolve, reject) => {
        if (state.officeList.length) {
          resolve(state.officeList);
        } else {
          YunGuiHttpClient(api).then(res => {
            const list = [];
            res.data.rows.forEach(result => {
              const item = result;
              item.label = result.officeName;
              item.value = result.officeCode;
              list.push(item);
            });
            commit('setOfficeList', list);
            resolve(list);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取物业区域公司
    getRegionalCompanyList ({ state, commit }) {
      const api = CommonApiSet.getRegionalCompanyList;
      return new Promise((resolve, reject) => {
        if (state.regionalCompanyList.length) {
          resolve(state.regionalCompanyList);
        } else {
          YunGuiHttpClient(api).then(res => {
            const list = [];
            if (res.data) {
              Object.keys(res.data).forEach(key => {
                const item = {
                  label: res.data[key],
                  value: key
                };
                list.push(item);
              });
            }
            commit('setRegionalCompanyList', list);
            resolve(list);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取结束原因
    getEndReasonList ({ state, commit }) {
      const api = CommonApiSet.getEndReasonList;
      return new Promise((resolve, reject) => {
        if (state.endReasonList.length) {
          resolve(state.endReasonList);
        } else {
          YunGuiHttpClient(api).then(res => {
            const list = [];
            if (res.data.list && res.data.list.length) {
              res.data.list.forEach(result => {
                list.push({
                  label: result.endReasonDesc,
                  value: result.endReasonCode
                });
              });
            }
            commit('setEndReasonList', list);
            resolve(list);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 获取对接厂商下拉
    getFactoryTypeList ({ state, commit }) {
      const api = CommonApiSet.getFactoryType;
      return new Promise((resolve, reject) => {
        if (state.factoryTypeList.length) {
          resolve(state.factoryTypeList);
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setFactoryTypeList', res.data.rows);
            resolve(res.data.rows);
          }).catch(e => {
            resolve([]);
          });
        }
      });
    },
    // 设备商+厂商+设备类型 级联动(三级全量数据)
    getFactoryCascaderList ({ state, commit }) {
      const api = CommonApiSet.getFactoryCascaderApi;
      return new Promise((resolve, reject) => {
        if (state.factoryCascaderList.length) {
          resolve((JSON.parse(JSON.stringify(state.factoryCascaderList))));
        } else {
          YunGuiHttpClient(api).then(res => {
            commit('setFactoryCascaderList', res.data.rows);
            resolve(JSON.parse(JSON.stringify(res.data.rows)));
          }).catch(e => {
            resolve([]);
          });
        }
      });
    }
  }
};
