<!--
    Created by 程雨喵'mac on 2024/11/25.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：设备商 > 厂商 > 设备类型(三级联动)
-->
<style lang="less">

</style>

<template>
  <Cascader
    :value="currentValue"
    :disabled="disabled"
    placeholder="请选择"
    change-on-select
    :transfer="true"
    :data="dataList"
    @on-change="_change"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'YgFactoryTypeCascader',
  components: {},
  props: {
    level: {
      type: Number,
      /**
       * 1: 选设备商
       * 2：选设备商 + 厂商
       * 3：选设备商 + 厂商 + 设备类型
       */
      default: 2
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultValue: [],
      currentValue: [],
      dataList: []
    };
  },
  watch: {
    value (val) {
      this._change(val);
    }
  },
  created () {
    if (this.value.length) {
      this.defaultValue = JSON.parse(JSON.stringify(this.value));
    }
    this.requestData();
  },
  methods: {
    ...mapActions(['getFactoryCascaderList']),
    _change (value) {
      // console.log(this.currentValue);
      if (JSON.stringify(this.currentValue) === JSON.stringify(value)) return;
      this.currentValue = value;
      this.$emit('input', value);
      this.$emit('on-change', value);
    },
    getChildList (list, currentLevel) {
      const resList = [];
      list.forEach(v => {
        const dic = {
          value: v.key,
          label: v.desc
        };
        if (this.level > currentLevel && v.childList && v.childList.length) {
          dic.children = this.getChildList(v.childList, currentLevel + 1);
        }
        resList.push(dic);
      });
      return resList;
    },
    requestData () {
      this.getFactoryCascaderList().then(list => {
        this.dataList = this.getChildList(list, 0);
      });
    }
  }
};
</script>
