<!--
    Created by 程雨喵'mac on 2024/1/23.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：计费规则
-->
<style lang="less">
.order-detail-dv-money-rules {
  padding-bottom: @containerGap * 1.5;
  .card-content {
    // 按功率计费
    .price-use-power {
      width: 100%;
      .money-rules-table {
        flex: 1;
        border: 1px solid @lineColorNormal;
        border-bottom: 0;
        border-radius: @borderRadiusMin;
        .money-rules-table-row {
          display: flex;
          align-items: flex-start;
          border-bottom: 1px solid @lineColorNormal;
          line-height: @formHeight;
          font-size: 12px;
          &.is-header {
            background-color: @backColorNormal;
            color: @textColorLight;
            font-weight: 600;
            font-size: 13px;
          }
          .table-col {
            padding: 5px;
            .display-flex();
            .table-col-inner {
              width: 100%;
              .display-flex();
              white-space: nowrap;
              .ivu-input-number {
                flex: 1;
              }
            }
            &+.table-col {
              border-left: 1px solid @lineColorNormal;
            }
            &.col-t-0 {
              .flex-grow(100px);
              font-weight: 600;
              color: @textColorLight;
            }
            &.col-t-1 {
              flex: 1;
            }
            &.col-t-2 {
              .flex-grow(180px);
            }
            &.col-t-3 {
              .flex-grow(80px);
            }
          }
        }
      }
    }
    // 按电量计费
    .price-use-electric {
      width: 100%;
      .money-rules-table {
        border: 1px solid @lineColorNormal;
        border-bottom: 0;
        border-radius: @borderRadiusMin;
        .money-rules-table-row {
          display: flex;
          //align-items: flex-start;
          border-bottom: 1px solid @lineColorNormal;
          line-height: @formHeight;
          font-size: 12px;
          &.is-header {
            background-color: @backColorNormal;
            color: @textColorLight;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            .table-col {
              padding: 0;
              &.col-t-2 {
                .header-col-inner {
                  padding: 5px;
                }
              }
              .col-inner-merge {
                border-top: 1px solid @lineColorNormal;
                font-size: 12px;
                .merge-col {
                  padding: 3px;
                }
              }
            }
          }
          .table-col {
            .display-flex();
            flex-wrap: wrap;
            .light-text {
              font-size: 10px !important;
              color: @textColorLight;
            }
            .table-col-inner {
              width: 100%;
              .display-flex();
              white-space: nowrap;
              .ivu-input-number {
                .flex-grow(60px);
              }
            }
            .col-inner-merge {
              display: flex;
              .flex-grow(100%);
              .merge-col {
                padding: 5px;
                .display-flex();
                .flex-grow(50%);
                &+.merge-col {
                  border-left: 1px solid @lineColorNormal;
                }
              }
            }
            &+.table-col {
              border-left: 1px solid @lineColorNormal;
            }
            &.col-t-0 {
              .flex-grow(100px);
              font-weight: 600;
              color: @textColorLight;
            }
            &.col-t-1 {
              padding: 0 5px;
              flex: 1;
            }
            &.col-t-2 {
              .flex-grow(250px);
            }
            &.col-t-3 {
              .flex-grow(80px);
            }
          }
        }
      }
      .b-tip {
        margin-top: @containerGap;
        font-size: 12px;
        line-height: 12px;
        color: @textColorLight;
        text-align: center;
      }
    }
    // 按时长计费
    .price-use-duration {
      width: 100%;
      &-cell {
        height: 25px;
        line-height: 25px;
      }
    }
    // 结算配置
    .settlement-allocation-content {
      width: 100%;
      .money-rules-table {
        flex: 1;
        border: 1px solid @lineColorNormal;
        border-bottom: 0;
        border-radius: @borderRadiusMin;
        .money-rules-table-row {
          display: flex;
          //align-items: flex-start;
          border-bottom: 1px solid @lineColorNormal;
          line-height: @formHeight;
          font-size: 12px;
          &.is-header {
            background-color: @backColorNormal;
            color: @textColorLight;
            font-weight: 600;
            font-size: 13px;
          }
          .table-col {
            .display-flex();
            flex-wrap: wrap;
            .light-text {
              font-size: 10px !important;
              color: @textColorLight;
            }
            .table-col-inner {
              width: 100%;
              .display-flex();
              white-space: nowrap;
              .ivu-input-number {
                .flex-grow(60px);
              }
            }
            &+.table-col {
              border-left: 1px solid @lineColorNormal;
            }
            &.col-t-0 {
              .flex-grow(200px);
              font-weight: 600;
              color: @textColorLight;
            }
            &.col-t-1 {
              padding: 5px;
              flex: 1;
            }
          }
        }
      }
    }
  }
  .card-content:first-child{
    margin-bottom: @containerGap;
  }
}
</style>

<template>
  <yg-modal
    :width="700"
    :title="model.deviceRuleInfos.categoryDesc"
    :show-bottom="false"
    :back-type="2"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="order-detail-dv-money-rules"
    >
      <yg-card
        v-for="(item, index) in formItems"
        :key="index"
        :title="item.label"
        class="card-content"
      >
        <!--按功率计费-->
        <div
          v-if="model.deviceRuleInfos.category === 1 && item.key === 'rule'"
          slot="content"
          class="price-use-power"
        >
          <div class="money-rules-table">
            <!--头部-->
            <div class="money-rules-table-row is-header">
              <div
                v-for="(re, i) in tableHeaders[model.deviceRuleInfos.category - 1]"
                :key="i"
                class="table-col"
                :class="'col-t-' + i"
              >
                {{ re }}
              </div>
            </div>
            <!--实际内容-->
            <div
              v-for="(re, i) in model.deviceRuleInfos.configs"
              :key="'r-' + i"
              class="money-rules-table-row"
            >
              <div class="table-col col-t-0">
                第{{ re.level }}档
              </div>
              <div class="table-col col-t-1">
                <div
                  class="table-col-inner"
                >
                  {{ re.h5RangeMin }}瓦至{{ re.h5RangeMax || '∞' }}瓦之间
                </div>
              </div>
              <div
                class="table-col col-t-2"
              >
                <div
                  class="table-col-inner"
                >
                  1元可充{{ re.chargeDuration }}分钟
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--按电量计费-->
        <div
          v-if="model.deviceRuleInfos.category === 2 && item.key === 'rule'"
          slot="content"
          class="price-use-electric"
        >
          <div class="money-rules-table">
            <!--头部-->
            <div class="money-rules-table-row is-header">
              <div
                v-for="(re, i) in tableHeaders[model.deviceRuleInfos.category - 1]"
                :key="i"
                class="table-col"
                :class="'col-t-' + i"
              >
                <div class="header-col-inner">
                  {{ re }}
                </div>
                <div
                  v-if="i === 2"
                  class="col-inner-merge"
                >
                  <div class="merge-col">
                    电费
                  </div>
                  <div class="merge-col">
                    服务费
                  </div>
                </div>
              </div>
            </div>
            <!--实际内容-->
            <div
              v-for="(re, i) in model.deviceRuleInfos.configs"
              :key="'r-' + i"
              class="money-rules-table-row"
            >
              <div class="table-col col-t-0">
                时段{{ re.level }}
              </div>
              <div class="table-col col-t-1">
                <div class="table-col-inner">
                  {{ re.h5RangeMin }}点至{{ re.h5RangeMax }}点之间
                </div>
              </div>
              <div class="table-col col-t-2">
                <div class="table-col-inner">
                  <div
                    class="col-inner-merge"
                  >
                    <div class="merge-col">
                      {{ re.chargePrice }}
                      <span style="margin-left: 5px">元/度</span>
                    </div>
                    <div class="merge-col">
                      {{ re.servicePrice }}
                      <span style="margin-left: 5px">元/度</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="model.deviceRuleInfos.h5TimeServicePrice"
            class="b-tip"
          >
            {{ model.deviceRuleInfos.h5TimeServicePrice }}
          </div>
        </div>
        <!--按时长计费-->
        <div
          v-if="model.deviceRuleInfos.category === 3 && item.key === 'rule'"
          slot="content"
          class="price-use-duration"
        >
          <div
            v-for="(re, i) in model.deviceRuleInfos.configs"
            :key="'r-' + i"
            class="price-use-duration-cell"
          >
            <div>1元可充{{ re.chargeDuration }}分钟</div>
          </div>
        </div>
        <!--结算配置-->
        <div
          v-if="item.key === 'settlementAllocation'"
          slot="content"
          class="settlement-allocation-content"
        >
          <div class="money-rules-table">
            <!--头部-->
            <div class="money-rules-table-row is-header">
              <div
                v-for="(re, i) in settlementTableHeaders"
                :key="i"
                class="table-col"
                :class="'col-t-' + i"
              >
                <div class="header-col-inner">
                  {{ re }}
                </div>
                <div
                  v-if="i === 2"
                  class="col-inner-merge"
                >
                  <div class="merge-col">
                    电费
                  </div>
                  <div class="merge-col">
                    服务费
                  </div>
                </div>
              </div>
            </div>
            <!--实际内容-->
            <div
              v-for="(re, i) in settlementItems"
              :key="i"
              class="money-rules-table-row"
            >
              <div class="table-col col-t-0">
                <div class="table-col-inner">
                  {{ re.label }}
                </div>
              </div>
              <div class="table-col col-t-1">
                <div class="table-col-inner">
                  <yg-attribute-span
                    :text="model.orderChargeConfigInfo[re.key] + ''"
                    :can-text-unit="re.canTextUnit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </yg-card>
    </div>
  </yg-modal>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      formItems: [
        { label: '订单计费规则', key: 'rule' },
        { label: '订单结算配置', key: 'settlementAllocation' }
      ],
      // 功率 、 电量 、 时长顺序
      tableHeaders: [['档位', '瓦数区间', '可充电时长'], ['时段', '时段区间', '时段收费'], ['收费']],
      settlementTableHeaders: ['配置项', '配置值'],
      settlementItems: [
        { label: '结算功率虚位(瓦)', key: 'h5FictitiousPower', canTextUnit: '' },
        { label: '6分钟内免费充电', key: 'h5FreeConfig', canTextUnit: '' },
        { label: '最大充电比例', key: 'h5MaxChargingRatio', canTextUnit: '' },
        { label: '线损比例', key: 'h5LineLossRatio', canTextUnit: '' },
        { label: '最低消费电量(度)', key: 'minCostCapacity', canTextUnit: 'du' },
        { label: '订单最大功率(瓦)', key: 'h5OrderMaxPower', canTextUnit: '' }
      ]
    };
  },
  created () {
    console.log(this.model.orderSettleInfo);
  },
  methods: {
    onClose () {
      this.$emit('on-close');
    }
  }
};
</script>
