<!--
    Created by 程雨喵'mac on 2024/11/15.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：图表 + 日志
-->
<style lang="less">
.order-data-hook {
  height: 100%;
  .order-data-hook-inner {
    display: flex;
    align-items: flex-start;
    height: 100%;
    .left {
      position: relative;
      .flex-grow(50%);
      height: 100%;
      overflow: auto;
      .top-info {
        display: flex;
        align-items: flex-end;
        height: @formHeight;
        padding: 0 @containerGap;
        background-color: @backColorNormal;
        font-size: 11px;
        .info-item {
          &+.info-item {
            margin-left: @containerGap * 0.5;
          }
          .label {
            color: @textColorLight;
          }
          .value {
            color: @textColorStrong;
          }
          @keyframes odChartsTextShow {
            from { color: @errorColor; }
            50% { color: @textColorStrong; }
            to { color: @errorColor; }
          }
          .value.text-is-high-light {
            color: @errorColor;
            font-weight: 600;
            animation: odChartsTextShow 1s 5;
          }
        }
      }
      .hook-charts-outer {
        position: relative;
        &:hover {
          .left-line-tip {
            display: flex;
          }
        }
        .left-line-tip {
          display: none;
          position: absolute;
          left: @containerGap * 2;
          top: @containerGap * 2;
          padding: @containerGap * 0.5;
          border-radius: @borderRadiusMin;
          font-size: 10px;
          line-height: 12px;
          //background-color: @backColorNormal;
          border: 1px dashed @lineColorNormal;
          div+div {
            margin-left: @containerGap * 0.5;
          }
          .c-1 {
            color: @themeColor;
          }
          .c-2 {
            color: @finishColor;
          }
          .c-3 {
            color: @errorColor;
          }
        }
        .top-tip {
          position: absolute;
          right: @containerGap * 2;
          top: @containerGap * 2;
          //display: flex;
          //align-items: center;
          font-size: 12px;
          .desc {
            margin-top: 5px;
            color: @textColorLight;
          }
        }
      }
    }
    .right {
      flex: 1;
      height: 100%;
      overflow: auto;
    }
  }
}
</style>

<template>
  <yg-drawer
    width="100%"
    title="图表及日志"
    :show-bottom="false"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="order-data-hook"
    >
      <div class="order-data-hook-inner">
        <div class="left">
          <div class="top-info">
            <div
              v-for="(item, i) in infoItems"
              :key="i"
              class="info-item"
            >
              <span class="label">{{ item.label }}:</span>
              <span
                class="value"
                :class="item.isHighLight ? 'text-is-high-light' : ''"
              >
                <span v-if="item.showDuration">{{ chargeDuration || '-' }}</span>
                <span v-else>{{ model[item.key] || '-' }}</span>
              </span>
            </div>
          </div>
          <div class="hook-charts-outer">
            <v-charts
              v-if="chartsData"
              :show-header="false"
              :model="chartsData"
              @on-trigger="onTrigger"
            />
            <div
              v-if="chartsData"
              class="left-line-tip"
            >
              <div class="c-0">
                颜色提示:
              </div>
              <div class="c-1">
                蓝色功率
              </div>
              <div class="c-2">
                绿色电压
              </div>
              <div class="c-3">
                红色电量
              </div>
            </div>
            <div
              v-if="chartsData"
              class="top-tip"
            >
              <span style="margin-right: 5px">开启自动跟随日志:</span>
              <i-switch
                v-model="openSwitch"
                size="large"
              >
                <span
                  slot="open"
                >开启</span>
                <span
                  slot="close"
                >关闭</span>
              </i-switch>
              <div class="desc">
                *注：按空格键可快速切换
              </div>
            </div>
          </div>
        </div>
        <div
          class="right"
        >
          <v-log
            :model="model"
            :high-light-time="highLightTime"
            :auto-request="true"
            :show-header="false"
          />
        </div>
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import vCharts from './detail-subView/charts.vue';
import vLog from './detail-subView/log.vue';
import OrderApiSet from '@/request/api/OrderApiSet';
import { ChargingBusinessChartsModel } from '@/pages/060__rechargeManager/chargingBusinessOrder/model/OrderChatsModel';
import { onHandler, offHandler } from '@/libs/utils';

export default {
  components: { vCharts, vLog },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      infoItems: [
        { label: '订单号', key: 'orderNo' },
        { label: '消费金额', key: 'consumeMoney' },
        { label: '充电时间', key: 'h5ChargeTimeDuration' },
        { label: '充电时长', key: '', showDuration: true },
        { label: '结束原因', key: 'endReasonDesc', isHighLight: true }
      ],
      chartsData: null,
      highLightTime: '',
      chargeDuration: '',
      openSwitch: true
    };
  },
  watch: {
    highLightTime () {
      if (!this.openSwitch) return;
      const ele = document.getElementById(this.highLightTime);
      if (ele) {
        ele.scrollIntoView({
          block: 'nearest',
          behavior: 'auto' // 可选，平滑滚动效果
        });
      }
    }
  },
  created () {
    onHandler(window, 'keydown', this.addKeyboardObserver);
    if (this.model.realEndTimeStr && this.model.startTimeStr) {
      const s = (new Date(this.model.startTimeStr)).getTime();
      const e = (new Date(this.model.realEndTimeStr)).getTime();
      let gap = (e - s) / 1000;
      const h = Math.floor(gap / 3600);
      gap = gap - h * 3600;
      const m = Math.floor(gap / 60);
      const se = gap % 60;
      this.chargeDuration = `${h ? (h + '小时') : ''}${m ? (m + '分钟') : ''}${se ? (se + '秒') : ''}`;
    }
    this.requestCharts();
  },
  beforeDestroy () {
    offHandler(window, 'keydown', this.addKeyboardObserver);
  },
  methods: {
    addKeyboardObserver (e) {
      if (e.keyCode === 32) { // 32是空格
        this.openSwitch = !this.openSwitch;
      }
    },
    onClose () {
      this.$emit('on-close');
    },
    onTrigger (e) {
      this.highLightTime = e;
    },
    requestCharts () {
      this.chartsData = null;
      const api = OrderApiSet.orderDetailPowerElec;
      api.params = {
        orderNo: this.model.orderNo
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.chartsData = ChargingBusinessChartsModel.initModelWithData(resData);
      });
    }
  }
};
</script>
